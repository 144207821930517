import React, { useEffect, useRef, useState } from "react"
import constant from '../../Components/Services/constant';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();
function HomeActivities() {
    const [activityData, setActivityData] = useState([]);
    const [activityUrl, setActivityUrl] = useState('');
    const didMountRef = useRef(true)
    useEffect(() => {
        if (didMountRef.current) {
            apiServices.activitiesDataGetRequest().then(res => {
                if (res.data.status == "success") {
                    setActivityData(res.data.activitydata);
                    setActivityUrl(res.data.activity_url);
                }
            })
            didMountRef.current = false;
        }
    })
    return (
        <>
            <section className="team-one about-team-page">
                <div className="team-one__bubbles wow slideInRight" data-wow-delay="100ms" data-wow-duration="2500ms"
                    style={{ backgroundImage: "url(/assets/images/shapes/team-one-bubbles.png)" }}>
                </div>
                <div className="team-one-start-1 zoominout"><img src="/assets/images/shapes/team-one-star-1.png" alt="" /></div>
                <div className="team-one-start-2 zoominout-2"><img src="/assets/images/shapes/team-one-star-2.png" alt="" /></div>
                <div className="team-one-start-3 zoominout"><img src="/assets/images/shapes/team-one-star-3.png" alt="" /></div>
                <div className="team-one-start-4 zoominout-2"><img src="/assets/images/shapes/team-one-star-4.png" alt="" /></div>
                <div className="team-one-start-5 zoominout"><img src="/assets/images/shapes/team-one-star-5.png" alt="" /></div>
                <div className="container">
                    <div className="section-title text-center">
                        <span className="section-title__tagline text-white">Most Unforgettable Water Park Experience in Jaipur</span>
                        <h2 className="section-title__title text-white">Ready to Slide, Swim, and Soar?</h2>
                    </div>
                    <div className="dektop-activities">
                        <div className="row">
                            {
                                activityData.map((value, index) => (
                                    <div className="col-lg-3 wow fadeInUp" key={index}>
                                        <div className="team-one__single">
                                            <div className="team-one__img">
                                                <img src={value.activity_image != null ? activityUrl + value.activity_image : constant.DEFAULT_IMAGE} alt="activity_image" />
                                            </div>
                                            <div className="team-one__content">
                                                <div className="team-one__title-box">
                                                    <h3 className="team-one__name">{value.activity_name}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ))}

                        </div>
                    </div>

                    <div className="mobile-activities">
                        <OwlCarousel
                            className="owl-theme thm-owl__carousel gallery-carousel-page__carousel"
                            loop={true}
                            dots={false}
                            margin={10}
                            nav={false}
                            navText={["<span class='fa fa-angle-left'></span>", "<span class='fa fa-angle-right'></span>"]}
                            responsive={{
                                0: { items: 1 },
                                468: { items: 2 },
                                992: { items: 3 },
                                1200: { items: 5 }
                            }} >

                            {
                                activityData.map((value, index) => (


                                    <div className="team-one__single" key={index}>
                                        <div className="team-one__img">
                                            <img src={value.activity_image != null ? activityUrl + value.activity_image : constant.DEFAULT_IMAGE} alt="activity_image" />
                                        </div>
                                        <div className="team-one__content">
                                            <div className="team-one__title-box">
                                                <h3 className="team-one__name">{value.activity_name}</h3>


                                            </div>
                                        </div>
                                    </div>

                                ))}

                        </OwlCarousel>
                    </div>

                </div>
            </section>


        </>
    )
}
export default HomeActivities;