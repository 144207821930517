import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import constant from "../../Components/Services/constant";
import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Helmet } from "react-helmet";

let apiServices = new ApiService();
function Rides() {
  const [pageData, setPageData] = useState({});
  const [pageContent, setPageContent] = useState("");
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [rideData, setRideData] = useState([]);
  const [rideUrl, setRideUrl] = useState("");
  const { slug } = useParams();
  const didMountRef = useRef(true);

  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
        page_url: "rides",
      };
      apiServices.pagePostRequest(dataString).then((res) => {
        if (res.data.status === "success") {
          setPageData(res.data.pageData);
          setPageContent(res.data.pageData.page_content);
          setHeaderImageUrl(res.data.page_header_url);
        }
      });
      apiServices.ridesgalleryDataGetRequest().then((res) => {
        if (res.data.status === "success") {
          setRideData(res.data.ridedata);
          setRideUrl(res.data.ride_url);
        }
      });
    }
    didMountRef.current = false;
  });

  return (
    <>
      <Helmet>
      <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Pink Pearl"}</title>
      <meta
          name="description"
          itemprop="description"
          content={pageData?.page_meta_desc != null ? pageData?.page_meta_desc :"Pink Pearl"}
        />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Pink Pearl" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Pink Pearl" />
        <meta property="og:url" content={window.location.href} />
        {pageData?.page_meta_desc != null ? (
            <meta property="og:description" content={pageData?.page_meta_desc } />
            ) : (
            <meta property="og:description" content="Pink Pearl" />
            )}
        {pageData?.page_header_image ? (
                  <meta property="og:image" content={headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                    property="og:image"
                    content={constant.FRONT_URL + 'assets/images/logo.png'}
                  />
                )}
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Pink Pearl" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        {pageData?.page_meta_desc != null ? (
                    <meta property="twitter:description" content={pageData?.page_meta_desc} />
                ) : (
                    <meta property="twitter:description" content="Pink Pearl" />
                )}
        {pageData?.page_header_image ? (
                  <meta property="twitter:image" content={ headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                  property="twitter:image"
                    content={constant.FRONT_URL + 'assets/images/logo.png'}
                  />
                )}
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{
            backgroundImage: `url(${
              headerImageUrl + pageData.page_header_image
            })`,
            backgroundSize: "cover",
            backgroundPosition: "100%",
          }}
        ></div>
        <div className="page-header-bubble">
          <img src="/assets/images/shapes/page-header-bubble.png" alt="image" />
        </div>
        <div className="container">
          <div className="page-header__inner">
            <h1>{pageData.page_header}</h1>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>{pageData.page_name}</li>
            </ul>
          </div>
        </div>
      </section>

      {pageContent != null ? (
        <div dangerouslySetInnerHTML={{ __html: pageContent }}></div>
      ) : (
        ""
      )}

      <section className="section-padding">
        <div className="container">
          {rideData.map((value, index) => {
            if (index % 2 === 0) {
              return (
                <div className="row mb-5" key={index}>
                  <div className="col-lg-6">
                    <div className="get-to-know__left">
                      <div className="get-to-know__img wow slideInLeft">
                        <img
                          alt="rides_image"
                          src={
                            value.ride_image != null
                              ? rideUrl + value.ride_image
                              : constant.DEFAULT_IMAGE
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="section-title text-left">
                      <h2 className="section-title__title">
                        {value.ride_name}
                      </h2>
                      <p>{value.ride_desc != null ? value.ride_desc : ""}</p>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="row mb-5" key={index}>
                  <div className="col-lg-6 order2">
                    <div className="section-title text-left">
                      <h2 className="section-title__title">
                        {value.ride_name}
                      </h2>
                      <p>{value.ride_desc != null ? value.ride_desc : ""}</p>
                    </div>
                  </div>
                  <div className="col-lg-6 order1">
                    <div className="get-to-know__left">
                      <div className="get-to-know__img wow slideInLeft">
                        <img
                          alt="ride_image"
                          src={
                            value.ride_image != null
                              ? rideUrl + value.ride_image
                              : constant.DEFAULT_IMAGE
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Rides;
