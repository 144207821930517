import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import constant from '../../Components/Services/constant';
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();
function HomeBlogs() {
  const [blogsData, setBlogsData] = useState([]);
  const [blogUrl, setBlogUrl] = useState("");
  const didMountRef = useRef(true);

  useEffect(() => {
    if (didMountRef.current) {
      apiServices.getfeaturedblogs().then((res) => {
        if (res.data.status == "success") {
          setBlogsData(res.data.blogsData);
          setBlogUrl(res.data.blog_url);
        }
      });
      didMountRef.current = false;
    }
  });

  return (
    <>
      {blogsData.length > 0 ? (
        <section className="blog-two">
          <div className="container">
            <div className="section-title text-center">
              <span className="section-title__tagline">
                Latest News & Articles
              </span>
              <h2 className="section-title__title">Our Blog</h2>
            </div>
            <div className="row">
              {blogsData.map((value, index) => (
                <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms" key={index}>
                  <div className="blog-one__single">
                    <div className="blog-one__img">
                      <img
                        src={
                          value.blog_image != null
                            ? blogUrl + value.blog_image
                            : constant.DEFAULT_IMAGE
                        }
                        alt=""
                      />
                      <a href={`/blogs/${value.blog_slug}`}>
                        <span className="blog-one__plus"></span>
                      </a>
                    </div>
                    <div className="blog-one__content">
                      <ul className="list-unstyled blog-one__meta">
                        <li>
                          <a href={`/blogs/${value.blog_slug}`}>
                            <i className="far fa-user-circle"></i> by Admin{" "}
                          </a>
                        </li>
                        <li>
                          <a href={`/blogs/${value.blog_slug}`}>
                            <i className="far fa-calendar"></i>{" "}
                            {moment(value.created_at).format(`D MMMM`)}
                          </a>
                        </li>
                      </ul>
                      <h3 className="blog-one__title">
                        <a href={`/blogs/${value.blog_slug}`}>
                          {value.blog_name}
                        </a>
                      </h3>
                      <div className="blog-one__btn-box">
                        <a href={`/blogs/${value.blog_slug}`}>
                          Read More <i className="fa fa-angle-right"></i>
                        </a>
                      </div>
                      <div className="blog-one__tag">
                        {/* <p>{value.blog_tag_name}</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
}
export default HomeBlogs;
