import React, { useEffect, useRef, useState } from "react"
import moment from 'moment';
import { ApiService } from "../../Components/Services/apiservices"
import constant from '../../Components/Services/constant';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Helmet } from "react-helmet";

let apiServices = new ApiService();
function Blogs() {
    const [pageData, setPageData] = useState({})
    const [pageContent, setPageContent] = useState('');
    const [headerImageUrl, setHeaderImageUrl] = useState('');
    const [blogData, setBlogData] = useState([])
    const [blogImageUrl, setBlogImageUrl] = useState('')

    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
            const dataString = {
                "page_url": "blogs"
            }
            apiServices.pagePostRequest(dataString).then(res => {

                if (res.data.status === "success") {
                    setPageData(res.data.pageData)
                    setPageContent(res.data.pageData.page_content)
                    setHeaderImageUrl(res.data.page_header_url)
                }

                apiServices.blogDataGetRequest().then(res => {
                    if (res.data.status == "success") {
                        setBlogData(res.data.blogsData)
                        setBlogImageUrl(res.data.blog_url)
                    }
                })
            })
        }
        didMountRef.current = false;
    }, [])
    return (

        <>

            <Helmet>
                <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Pink Pearl"}</title>
                <meta
                    name="description"
                    itemprop="description"
                    content={pageData?.page_meta_desc != null ? pageData?.page_meta_desc : "Pink Pearl"}
                />
                <meta name="keywords" content={pageData.page_meta_keyword} />
                <link rel="canonical" href={window.location.href} />
                <meta name="robots" content="index,follow" />
                <meta name="author" content="Pink Pearl" />
                <meta property="og:title" content={pageData.page_meta_title} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Pink Pearl" />
                <meta property="og:url" content={window.location.href} />
                {pageData?.page_meta_desc != null ? (
                    <meta property="og:description" content={pageData?.page_meta_desc} />
                ) : (
                    <meta property="og:description" content="Pink Pearl" />
                )}
                {pageData?.page_header_image ? (
                    <meta property="og:image" content={headerImageUrl + pageData.page_header_image} />
                ) : (
                    <meta
                        property="og:image"
                        content={constant.FRONT_URL + 'assets/images/logo.png'}
                    />
                )}
                <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@Pink Pearl" />
                <meta name="twitter:title" content={pageData?.page_meta_title != null ? pageData.page_meta_title : "Pink Pearl"} />
                {pageData?.page_meta_desc != null ? (
                    <meta property="twitter:description" content={pageData?.page_meta_desc} />
                ) : (
                    <meta property="twitter:description" content="Pink Pearl" />
                )}
                {pageData?.page_header_image ? (
                    <meta property="twitter:image" content={headerImageUrl + pageData.page_header_image} />
                ) : (
                    <meta
                        property="twitter:image"
                        content={constant.FRONT_URL + 'assets/images/logo.png'}
                    />
                )}
                <meta name="twitter:url" content={window.location.href} />
            </Helmet>

            <Header />
            <section className="page-header">
                <div className="page-header-bg" style={{ backgroundImage: `url(${headerImageUrl + pageData.page_header_image})`, backgroundSize: 'cover', backgroundPosition: '100%' }}>
                </div>
                <div className="page-header-bubble"><img src="/assets/images/shapes/page-header-bubble.png" alt="" /></div>
                <div className="container">
                    <div className="page-header__inner">

                        {pageData.page_header != null ? <h1>{pageData.page_header}</h1> : ""}
                        <ul className="thm-breadcrumb list-unstyled">
                            <li><a href="/">Home</a></li>
                            <li><span>/</span></li>
                            {pageData.page_name != null ? <li>{pageData.page_name}</li> : ""}
                        </ul>
                    </div>
                </div>
            </section>

            <section className="blog-page">
                <div className="container">
                    <div className="row">
                        {blogData.length > 0 ?
                            blogData.map((value, index) => (
                                <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms" key={index}>
                                    <div className="blog-one__single">
                                        <div className="blog-one__img">
                                            <img src={value.blog_image != null ? blogImageUrl + value.blog_image : constant.DEFAULT_IMAGE} alt="blog_image" />
                                            <a href={`/blogs/${value.blog_slug}`}>
                                                <span className="blog-one__plus"></span>
                                            </a>
                                        </div>
                                        <div className="blog-one__content">
                                            <ul className="list-unstyled blog-one__meta">
                                                <li><a href={`/blogs/${value.blog_slug}`}><i className="far fa-user-circle"></i> by Admin </a>
                                                </li>
                                                <li><a href={`/blogs/${value.blog_slug}`}><i className="far fa-calendar"></i> {moment(value.created_at).format(`D MMMM`)}</a>
                                                </li>
                                            </ul>
                                            <h3 className="blog-one__title"><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h3>
                                            <div className="blog-one__btn-box">
                                                <a href={`/blogs/${value.blog_slug}`}>Read More <i className="fa fa-angle-right"></i></a>
                                            </div>
                                            {/* <div className="blog-one__tag">
                                                <p>{value.blog_tag_name}</p>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            ))
                            : ""}

                    </div>
                </div>
            </section>

            <Footer />
        </>
    )




}

export default Blogs; 