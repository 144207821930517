import { useEffect } from 'react';
import {BrowserRouter as Router,Routes,Route,Navigate,} from 'react-router-dom';
import Home from './Containers/Home';
import Pages from './Containers/Pages';
import Blogs from './Containers/Blogs';
import Contact from './Containers/Contact';
import Rides from './Containers/Rides';
import Packages from './Containers/Packages';
import BookTickets from './Containers/BookTickets';
import BlogsDetails from './Containers/BlogsDetails';
import BlogsCategoryWise from './Containers/Blogs/blogscategorywise';
import BlogsTagWise from './Containers/Blogs/blogstagwise';
import Thankyou from './Containers/Thankyou';
import NotFound from './Containers/NotFound';
import Accommodations from './Containers/Accomodations';
import Wedding from './Containers/Weddings';
import TransactionFailed from './Containers/Transactionfailed';
import Gallery from './Containers/Gallery';



function App() {
  useEffect(() => {
        const currentUrl = window.location.href;
    const containsQuestionMark = currentUrl.includes('?');
    if(containsQuestionMark){
      window.location.href = '/404';
    }
  }, []);
  return (
    <div className="App">
    <Router>

<Routes>
<Route exact path ="/thankyou/:id" activeClassName= "active" element={<Thankyou /> } />
<Route exact path ="/transaction-failed/:id/:status" activeClassName= "active" element={<TransactionFailed /> } />
<Route exact path ="/" activeClassName= "active" element={<Home /> } />
<Route exact path ="/contact" activeClassName= "active" element={<Contact /> } />
<Route exact path ="/blogs" activeClassName= "active" element={<Blogs /> } />
<Route exact path='/blogs/:slug' activeClassName="active" element={ <BlogsDetails /> }/>
<Route exact path ="/book-tickets" activeClassName= "active" element={<BookTickets /> } />
<Route exact path ="/book-tickets/:id" activeClassName= "active" element={<BookTickets /> } />
<Route exact path ="/rides" activeClassName= "active" element={<Rides /> } />
<Route exact path ="/packages" activeClassName= "active" element={<Packages /> } />
<Route exact path ="/gallery" activeClassName= "active" element={<Gallery /> } />

<Route exact path="/404" activeClassName="active" element={<NotFound />} />
<Route exact path="/accommodations" activeClassName="active" element={<Accommodations/>} />
<Route exact path='/blogs/category/:slug' activeClassName="active" element={ <BlogsCategoryWise /> }/>
<Route exact path='/blogs/tags/:slug' activeClassName="active" element={ <BlogsTagWise /> }/>
<Route exact path="/wedding" activeClassName="active" element={<Wedding/>} />
<Route path="*" element={<NotFound />} />
<Route exact path='/:slug' activeClassName="active" element={<Pages/>} />

</Routes>
    </Router>
    </div>
  );
}

export default App;
