import React, { useEffect, useRef,useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ApiService } from "../../Components/Services/apiservices";
import numeral from 'numeral';
import { validEmail, validNumber } from '../../Components/Elements/Regex';
import Alert from 'react-bootstrap/Alert';
import DatePicker from 'react-datepicker';
import SpinnerLoader from "../../Components/Elements/spinnerLoader"
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
let apiServices = new ApiService();

const CommonModal = ({ buttonheading = 'Buy Now' ,range, ticketId }) => {
  const [show, setShow] = useState(false);
  const [couponApplyStatus, setCouponApplyStatus] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setloader] = useState(false);
  const [applyloader, setapplyloader] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [maxRange, setMaxRange] = useState("");

  const [adultTicketsData, setAdultTicketsData] = useState({});
  const [childTicketsData, setchildTicketsData] = useState({});
  const[settingData, setSettingData] = useState("")
  const didMountRef = useRef(true)
  const [bookingDetails, setBookingDetails] = useState({
    no_of_adults: 1,
    no_of_child: 0,
    booking_date: new Date(),
    user_name: "",
    user_mobile: "",
    user_email: "",
    child_price:"",
    adult_price:"",
    booking_type: 0,
  });
  const [itemTotal, setItemTotal] = useState(0)
  const [couponAmount, setCouponAmount] = useState(0)
  const [couponCode, setCouponCode] = useState('')
  const [finalAmount, setFinalAmount] = useState(0)
  const current_Date=new Date()
  const handleClose = () => {
    setShow(false);
    setStep(1);
    setBookingDetails((prevState) => ({
      ...prevState,
      ['no_of_adults']: 1,
      ['no_of_child']: 0,
      ['booking_date']: new Date(),
      ['user_name']: '',
      ['user_mobile']: '',
      ['user_email']: '',
      ['booking_type']: 0,
      ['adult_price']:"",
      ['child_price']:''
    }));
    setCouponApplyStatus(false)
    setCouponCode('')
    setCouponAmount(0)
  }
  const handleCoupon = (e) => {
    setErrorMessage("")
    setCouponCode(e.target.value)
  }

      useEffect(()=>{
        if(didMountRef.current){
          console.log("Ticket ID:", ticketId);
          getsettingData()

        }
        didMountRef.current = false

      })

      const getsettingData = (()=>{

        apiServices.settingsdataRequest().then(res =>{
          if(res.data.status == "success"){
          setSettingData(res.data.sitesettings)

          }
      })

      })
      const maxDate = new Date();

  const showmodal = () => {
    setShow(true);
    const dataString={
      date:moment(current_Date.toString()).format(`DD-MM-YYYY`),
      ticket_id: ticketId,
    }
    apiServices.ticketslistPostRequest(dataString).then((res) => {
      if (res.data.status == "success") {
        setAdultTicketsData(res.data.ticketsdata);
        // setchildTicketsData(res.data.ticketsdata);
    

        setMaxRange(maxDate.setDate(maxDate.getDate() + res.data.ticketsdata[0].date_range))
        console.log("maxDate", maxDate.getDate())
      }
    });
  };

  const validateBookingDetails = () => {
    setErrorMessage("");
    setCouponApplyStatus(false)
    setCouponCode('')
    setCouponAmount(0)
    // setErrorMessage("We are currently not accepting online bookings.");
    //   return false;
    if (bookingDetails.no_of_adults === "" && bookingDetails.no_of_child === "") {
      setErrorMessage("Please enter No of Adult or No of Child");
      return false;
    }
    else if (bookingDetails.no_of_child == 0 && bookingDetails.no_of_adults == 0) {
      setErrorMessage("Please enter No of Adult or No of Child");
      return false;
    } else if (bookingDetails.booking_date === "") {
      setErrorMessage("Please choose Date");
      return false;
    }
    setloader(true)
    const dataString = {
      date : moment(bookingDetails?.booking_date.toString()).format(`YYYY-MM-DD`),
      ticket_id: ticketId,
    };
    apiServices.ticketslistPostRequest(dataString).then((res) => {
      if (res.data.status == "success") {
        setAdultTicketsData(res.data.ticketsdata[0]);

        // setchildTicketsData(res.data.ticketsdata[0]);
        setBookingDetails((prevState) => ({
          ...prevState,
          child_price:res.data.ticketsdata[0]?.kid_ticket_price ,
        })); 
        setBookingDetails((prevState) => ({
          ...prevState,
          adult_price:res.data.ticketsdata[0]?.adult_ticket_price ,
        }));
        let childamount = Number(bookingDetails.no_of_child) * Number(res.data.ticketsdata[0].kid_ticket_price)
        let adultamount = Number(bookingDetails.no_of_adults) * Number(res.data.ticketsdata[0].adult_ticket_price)
        setItemTotal(Number(childamount+adultamount)); 
        setFinalAmount(Number(childamount+adultamount)); 
        setloader(false)
        setStep(2);
        
      }
      else {
        setloader(false)
      }
    }) 
  };
  const beckStep = () => {
    setErrorMessage("")
    setCouponApplyStatus(false)
    setCouponCode('')
    setCouponAmount(0)
    setStep(1);
  }

  const orderTransaction = () => {
    //     onScriptLoad('bca8374914eb4f90b684ef2d299dad3e1692355323306','PINKB000205','600')
    // return false;
    setErrorMessage("");
    if (bookingDetails.user_name === "") {
      setErrorMessage("Please enter Full Name");
      return false;
    } else if (bookingDetails.user_mobile === "") {
      setErrorMessage("Please enter Mobile Number");
      return false;
    } else if (!validNumber.test(bookingDetails.user_mobile)) {
      setErrorMessage("Please enter valid Mobile Number");
      return false;
    } else if (bookingDetails.user_email === '') {
      setErrorMessage("Please enter Email Id");
      return false;
    } else if (!validEmail.test(bookingDetails.user_email)) {
      setErrorMessage("Please enter valid Email Id");
      return false;
    } else if (bookingDetails.no_of_adults < 0) {
      setErrorMessage("Please enter valid No of Adult");
      return false;
    }

   else  if (bookingDetails.no_of_child < 0) {
      setErrorMessage("Please enter valid No of Child");
      return false;
    }
   
    else if (bookingDetails.no_of_child == 0 && bookingDetails.no_of_adults == 0) {
      setErrorMessage("Please enter No of Adult or No of Child");
      return false;
    }
    setloader(true)
    const dataString = {
      "promocode": couponCode,
      "totalAmount": itemTotal,
      "couponAmount": couponAmount,
      "couponCode": couponCode,
      "finalAmount": finalAmount,
      "ticket_id": ticketId,
      bookingDetails,

    }
    apiServices.postRequest('createdPaytmOrderId', dataString).then((res) => {
      if (res.data.status == "success") {
        
        if (res.data.row_temp_trans) {
          onScriptLoad(res.data.row_temp_trans.temp_trans_token, res.data.row_temp_trans.temp_trans_order_id, res.data.row_temp_trans.temp_trans_finalamt)
          setTimeout(()=>{
            setloader(false)
          },1600)
          
        }
        // setOrderId(res.data.order_number)
        // setStep(3)
      } else {
        setloader(false)
        setErrorMessage(res.data.message)
      }
    });
  };


  const onScriptLoad = (txnToken, orderId, amount) => {
    var config = {
      "root": "",
      "flow": "DEFAULT",
      "merchant": {
        "name": "Pink Pearl",
        "logo": "https://pinkpearl.co.in/assets/images/logo.png"
      },
      "style": {
        "headerBackgroundColor": "#8dd8ff",
        "headerColor": "#3f3f40"
      },
      "data": {
        "orderId": orderId,
        "token": txnToken,
        "tokenType": "TXN_TOKEN",
        "amount": amount
      },
      "handler": {
        "notifyMerchant": function (eventName, data) {
          if (eventName == 'SESSION_EXPIRED') {
            alert("Your session has expired!!");
            window.location.reload();
          }
        }
      }
    };
    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.init(config)
        .then(function onSuccess() {
          console.log('Before JS Checkout invoke');
          window.Paytm.CheckoutJS.invoke();
        })
        .catch(function onError(error) {
          console.log('Error => ', error);
        });
    }
  };

  // const confirmBooking = () => {
  //   setErrorMessage("");
  //   if (bookingDetails.user_name === "") {
  //     setErrorMessage("Please enter Full Name");
  //     return false;
  //   } else if (bookingDetails.user_mobile === "") {
  //     setErrorMessage("Please enter Mobile Number");
  //     return false;
  //   } else if (!validNumber.test(bookingDetails.user_mobile)) {
  //     setErrorMessage("Please enter valid Mobile Number");
  //     return false;
  //   }else if (bookingDetails.user_email === '') {
  //     setErrorMessage("Please enter Email Id");
  //     return false;
  //   }else if (!validEmail.test(bookingDetails.user_email)) {
  //     setErrorMessage("Please enter valid Email Id");
  //     return false;
  //   }else if (bookingDetails.no_of_adults === "") {
  //     setErrorMessage("Please enter No of Adult");
  //     return false;
  //   } else if (bookingDetails.no_of_child === "") {
  //     setErrorMessage("Please enter No of Child");
  //     return false;
  //   } 
  //   const dataString = {
  //     "promocode": couponCode,
  //     "totalAmount": itemTotal,
  //     "couponAmount": couponAmount,
  //     "couponCode": couponCode,
  //     "finalAmount": finalAmount,
  //     bookingDetails,

  //   }
  //   apiServices.postRequest('confirm-booking-process',dataString).then((res) => {
  //     if (res.data.status == "success") {
  //       setOrderId(res.data.order_number)
  //       setStep(3)
  //     }else{
  //       setErrorMessage(res.data.message)
  //     }
  //   });
  // };
  const onTodoChange = (e) => {
    setErrorMessage("")
    const { name, value } = e.target;
    setBookingDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const applyCoupon = () => {
    if (couponCode === '') {
      setErrorMessage('Please enter coupon code')
      return false
    }
    setapplyloader(true)
    const dataString = {
      "promocode": couponCode,
      "totalAmount": itemTotal
    }
    apiServices.postRequest('apply-coupon-process', dataString).then((res) => {
      if (res.data.data.status == "success") {
        setCouponAmount(res.data.data.discountAmount)
        setFinalAmount(Number(finalAmount) - Number(res.data.data.discountAmount));
        setCouponApplyStatus(true)
        setapplyloader(false)
        setSuccessMessage(res.data.data.notification)
        setTimeout(() => {
          setSuccessMessage("")
          setErrorMessage("")
        }, 2000);
      } else {
        setapplyloader(false)
        setErrorMessage(res.data.data.notification)
        setTimeout(() => {
          setSuccessMessage("")
          setErrorMessage("")
        }, 2000);
      }
    });
  }

  const removeCoupon = () => {
    setCouponCode("")
    setErrorMessage("")
    setCouponAmount(Number(0.00))
    setFinalAmount(Number(finalAmount) + Number(couponAmount));
    setCouponApplyStatus(false)
  }
  const formatDate = (date) => {
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayIndex = date.getDay();
    const dayName = dayNames[dayIndex];
    setBookingDetails((prevState) => ({
      ...prevState,
      booking_date: date,
    }))
  };


  return (
   
    <div>
      <a href="javascript:;" className="thm-btn welcome-one__btn mt-5" onClick={showmodal}>
        {buttonheading} <i className="fa fa-angle-right"></i>
      </a>
     
      {show && (
        <Modal show={show} onHide={handleClose} className="btmodal">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>
          
           <div className="btmodal-content bookform">
            {step === 1 && (
              <div className="step-first">
                <div className="section-title">
                  <span className="section-title__tagline">Make a booking</span>
                  <h4 className="section-title__title-small">
                    Booking at Pink Pearl Waterpark
                  </h4>
                </div>
                {successMessage !== "" ? (
                  <Alert variant="success">{successMessage}</Alert>
                ) : null}
                {errorMessage !== "" ? (
                  <Alert variant="danger">{errorMessage}</Alert>
                ) : null}
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group mb-3">
                      <label>No of Adult</label>
                      <input
                        type="number"
                        placeholder="No of Adult"
                        name="no_of_adults"
                        min="0"
                        value={bookingDetails.no_of_adults}
                        onChange={(e) => onTodoChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group mb-3">
                      <label>No of Child</label>
                      <input
                        type="number"
                        placeholder="No of Child"
                        name="no_of_child"
                        min="0"
                        value={bookingDetails.no_of_child}
                        onChange={(e) => onTodoChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group mb-3">
                      <label>Date</label>
                      <DatePicker
                        selected={bookingDetails?.booking_date}
                        name="booking_date"
                        onChange={(date) => { formatDate(date) }}
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()} 
                        maxDate={maxRange} 
                      />
                    </div>
                  </div>
                      <div className="col-lg-3">
                    <div className="form-group mb-3">
                      <label>&nbsp;</label>
                      <button
                        className="thm-btn welcome-one__btn book-but"
                        onClick={validateBookingDetails}
                        disabled={settingData.booking_open_close !== 1 ||  loader}
                       
                      >
                        {loader ? (
                          <img src="/assets/images/loder01.gif" width="60px" height="11px" />
                        ) : (
                          "Continue"
                        )}
                      </button>
                    </div>
                  </div>

                  {settingData && settingData.booking_open_close == 0 && (
                    <p>
                      <b>Please Note:</b>
                      <span style={{ color: "#ff0000",marginLeft: '5px' }}>{settingData.booking_close_note}</span>
                    </p>
                        )}


                  {/* <p><b>Please Note:</b> The ticket will be valid for the same day you book the ticket.</p> */}
                </div>
              </div>
            )}
            {step === 2 && (
              <div className="step-second">
                <div className="btmodal-left">
                  <div className="section-title">
                    <span className="section-title__tagline">
                      Make a booking
                    </span>
                    <h4 className="section-title__title-small">
                      Enter your details
                    </h4>
                  </div>
                  {successMessage !== "" ? (
                    <Alert variant="success">{successMessage}</Alert>
                  ) : null}
                  {errorMessage !== "" ? (
                    <Alert variant="danger">{errorMessage}</Alert>
                  ) : null}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label>Full Name</label>
                        <input
                          type="text"
                          name="user_name"
                          value={bookingDetails.user_name}
                          onChange={(e) => onTodoChange(e)}
                        ></input>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label>Mobile Number</label>
                        <input
                          type="text"
                          name="user_mobile"
                          value={bookingDetails.user_mobile}
                          onChange={(e) => onTodoChange(e)}
                        ></input>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label>Email Id</label>
                        <input
                          type="text"
                          name="user_email"
                          value={bookingDetails.user_email}
                          onChange={(e) => onTodoChange(e)}
                        ></input>
                      </div>
                    </div>
                      <div className="col-lg-3">
                      <div className="form-group mb-3">
                        <button
                          className="thm-btn welcome-one__btn book-but"
                          onClick={beckStep}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btmodal-right">
                  <div className="form-group">
                    <div className="apply-coupons-box">
                      <input
                        type="text"
                        placeholder="Gift card or discount code"
                        value={couponCode}
                        onChange={e=>handleCoupon(e)}
                      />
                      {couponApplyStatus === false ? 
                        <button className="thm-btn welcome-one__btn book-but mt-2" onClick={applyCoupon}  disabled={applyloader}>
                           {applyloader ? (
                          <img src="/assets/images/loder01.gif" width="60px" height="11px" />
                        ) : (
                          "Apply"
                        )}
                          
                        </button>
                        :
                        <button className="thm-btn welcome-one__btn book-but mt-2" onClick={removeCoupon}>
                          Remove
                        </button>
                      } 
                    </div>
                  </div>
                  <div className="price-details-box mt-4">
                    <h5>Price Details</h5>
                    <div className="pcb-list mb-4">
                      <ul>
                      <li>
                          Child X {bookingDetails.no_of_child}<span className="ml-auto">₹{numeral(Number(bookingDetails.no_of_child) * Number(adultTicketsData.kid_ticket_price)).format('0,0.00')}</span>
                        </li>
                        <li>
                          Adult X {bookingDetails.no_of_adults}<span className="ml-auto">₹{numeral(Number(bookingDetails.no_of_adults) * Number(adultTicketsData.adult_ticket_price)).format('0,0.00')}</span>
                        </li>

                        <li>
                          Coupon Discount
                          <span className="ml-auto tx-green">₹{numeral(couponAmount).format('0,0.00')}</span>
                        </li>
                      </ul>
                    </div>
                    <hr />
                    <div className="pcb-list-second">
                      <ul>
                        <li>
                          Total Amount<span className="ml-auto">₹{numeral(finalAmount).format('0,0.00')}</span>
                        </li>
                      </ul>
                    </div>
                    <hr />
                  </div>
                  <div className="form-group mb-3">
                    <button
                      className="thm-btn welcome-one__btn book-but"
                      onClick={() => {
                        // confirmBooking();
                        orderTransaction();
                       

                      }}
                      disabled={loader}

                    >
                       {loader ? (
                          <img src="/assets/images/loder01.gif" width="60px" height="11px" />
                        ) : (
                          "Pay Now"
                        )}
                      
                    </button>
                  </div>
                </div>
              </div>
            )}
            {step === 3 && (
              <div className="step-first text-center">
                <div className="section-title">
                  <img src="/assets/images/check-circle.gif" style={{ width: '100px' }} className="mb-20" />
                  <h2><strong>Thank You</strong></h2>
                  <h5>Your Enquiry Successfully Submitted</h5>
                  {/* <p>Your booking ID <strong>#{orderId}</strong></p> */}
                  <p>Please check your email for ticket booking details.</p>
                </div>
              </div>
            )}
          </div>
      
     
         
        </Modal>
      )}
    </div>
  );
};

export default CommonModal;
