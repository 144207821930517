import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import constant from "../../Components/Services/constant";
import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Helmet } from "react-helmet";

let apiServices = new ApiService();
function Packages() {
  const [pageData, setPageData] = useState({});
  const [pageContent, setPageContent] = useState("");
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [packagesData, setPackagesData] = useState([]);
  const [packagesUrl, setPackagesUrl] = useState("");
  const [saveData, setSaveData] = useState({
    contact_name: "",
    contact_email: "",
    contact_mobile: "",
    contact_message: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessmessage] = useState("");

  const { slug } = useParams();
  const didMountRef = useRef(true);
  const [settingData, setSettingData] = useState({})
  useEffect(() => {
    if (didMountRef.current) {
      getSettingData()
      const dataString = {
        page_url: "packages",
      };
      apiServices.pagePostRequest(dataString).then((res) => {
        if (res.data.status === "success") {
          setPageData(res.data.pageData);
          setPageContent(res.data.pageData.page_content);
          setHeaderImageUrl(res.data.page_header_url);
        }
      });
      apiServices.packageslistGetRequest().then((res) => {
        if (res.data.status == "success") {
          setPackagesData(res.data.packagesdata);
          setPackagesUrl(res.data.package_url);
        }
      });
    }
    didMountRef.current = false;
  });
  const getSettingData = () => {

    apiServices.settingsdataRequest().then(res => {
      if (res.data.status == "success") {
        setSettingData(res.data.sitesettings)

      }
    })
  }
  const handlechangeData = (e) => {
    const value = e.target.value;
    const key = e.target.name;
    setSaveData({ ...saveData, [key]: value });
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const submitmessage = (e) => {
    // console.log(saveData)
    e.preventDefault();

    if (!validateEmail(saveData.contact_email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }
    if (saveData.contact_name == "") {
      document.getElementById("contact_name").style.border = "1px solid red";
      return false;
    } else if (saveData.contact_email == "") {
      document.getElementById("contact_email").style.border = "1px solid red";
      return false;
    } else if (saveData.contact_mobile == "") {
      document.getElementById("contact_mobile").style.border = "1px solid red";
      return false;
    } else if (saveData.contact_message == "") {
      document.getElementById("contact_message").style.border = "1px solid red";
      return false;
    }

    const dataString = {
      contact_enquiry: pageData.page_id,

      contact_name: saveData.contact_name,
      contact_email: saveData.contact_email,
      contact_mobile: saveData.contact_mobile,
      contact_message: saveData.contact_message,
    };
    apiServices.contactusprocessPostRequest(dataString).then((res) => {
      console.log(res);
      if (res.data.status === "success") {
        setSuccessmessage(res.data.message);
      } else if (res.data.status === "error") {
        setErrorMessage(res.data.message);
      }
      setTimeout(() => {
        setSuccessmessage("");
        window.location.reload();
      }, 3000);
    });
  };

  return (
    <>
      <Helmet>
      <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Pink Pearl"}</title>
      <meta
          name="description"
          itemprop="description"
          content={pageData?.page_meta_desc != null ? pageData?.page_meta_desc :"Pink Pearl"}
        />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Pink Pearl" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Pink Pearl" />
        <meta property="og:url" content={window.location.href} />
        {pageData?.page_meta_desc != null ? (
            <meta property="og:description" content={pageData?.page_meta_desc } />
            ) : (
            <meta property="og:description" content="Pink Pearl" />
            )}
        {pageData?.page_header_image ? (
                  <meta property="og:image" content={headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                    property="og:image"
                    content={constant.FRONT_URL + 'assets/images/logo.png'}
                  />
                )}
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Pink Pearl" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        {pageData?.page_meta_desc != null ? (
                    <meta property="twitter:description" content={pageData?.page_meta_desc} />
                ) : (
                    <meta property="twitter:description" content="Pink Pearl" />
                )}
        {pageData?.page_header_image ? (
                  <meta property="twitter:image" content={ headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                  property="twitter:image"
                    content={constant.FRONT_URL + 'assets/images/logo.png'}
                  />
                )}
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>
      <Header />

      <section className="page-header">
        <div
          className="page-header-bg"
          style={{
            backgroundImage: `url(${headerImageUrl + pageData.page_header_image
              })`,
            backgroundSize: "cover",
            backgroundPosition: "100%",
          }}
        ></div>
        <div className="page-header-bubble">
          <img
            src="/assets/images/shapes/page-header-bubble.png"
            alt="gallery_image"
          />
        </div>
        <div className="container">
          <div className="page-header__inner">
            <h1>{pageData.page_header}</h1>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>{pageData.page_name}</li>
            </ul>
          </div>
        </div>
      </section>
      {settingData.marquee_tag != null ?
        <section>
          <div className="marque-package theme-bg">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-8 col-8">
                <marquee className="ht"><span>{settingData.marquee_tag}</span></marquee>
              </div>

            </div>
          </div>
        </section>
        : ""
      }
      {/* <section className="section-padding">
        <div className="container">
          <div className="row mb-5">
            <div className="col-xl-6">

              <img
                src="/assets/images/Holi2.jpg"
                alt="package image"
                style={{ width: "100%" }}
              />


            </div>
            <div className="col-xl-6">
              <div className="welcome-one__right">
                <div className="section-title text-left">
                </div>
                <div className="section-title text-left mt-4">
                  <h2 className="section-title__title " style={{ color: "#4bbed1" }}>
                    Splash into Holi Bliss!
                  </h2>


                </div>
                <p className="welcome-one__text-2">"Indulge in vibrant festivities with our Holi Bliss offer at Pink Pearl Hotel. Enjoy a one-night stay filled with colors, festivities, and delectable treats. Book now for an unforgettable celebration!"<br/>
                Included:
                </p>
                 
               <div className="welcome-one__points-box">
                    <ul className="list-unstyled welcome-one__points">
                        <li >
                          <div className="icon">
                            <span className="icon-tick"></span>
                          </div>
                          <div className="text">
                            <p>Breakfast</p>
                          </div>
                        </li>
                        <li >
                          <div className="icon">
                            <span className="icon-tick"></span>
                          </div>
                          <div className="text">
                            <p>Lunch</p>
                          </div>
                        </li>
                        <li >
                          <div className="icon">
                            <span className="icon-tick"></span>
                          </div>
                          <div className="text">
                            <p>Dinner</p>
                          </div>
                        </li>

             
                    </ul>
                  </div>
       
                <div className="d-flex">
                  <div className="welcome-one__btn-call-box pe-3">
                    <div className="welcome-one__btn-box">
                      <a
                        data-bs-target="#equoteModal"
                        data-bs-toggle="modal"
                        href="#equoteModal"
                        className="thm-btn welcome-one__btn"
                        a="true"
                      >
                        Enquiry <i className="fa fa-angle-right"></i>
                      </a>
                    </div>
                  </div>
                  <div className="welcome-one__btn-call-box">
                    <div className="welcome-one__btn-box">
                      <a href="tel:9314512035" className="thm-btn welcome-one__btn" >
                        Call Now <i className="fa fa-angle-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section style={{paddingBottom: "60px"}}>
        <div className="container">
          {packagesData.map((value, index) => (
            <div className="row mb-5 pt-5" key={index}>
              <div className="col-xl-6">
                <div>
                  <img
                    src={
                      value.package_image != null
                        ? packagesUrl + value.package_image
                        : constant.DEFAULT_IMAGE
                    }
                    alt="package image"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="welcome-one__right">
                  <div className="section-title text-left">
                    <h2 className="section-title__title">
                      {value.package_title}
                    </h2>
                  </div>
                  <p
                    className="welcome-one__text-2"
                    dangerouslySetInnerHTML={{ __html: value.package_desc }}
                  ></p>
                  <div className="welcome-one__points-box">
                    <ul className="list-unstyled welcome-one__points">
                      {value.package_highlight.map((subvalue, index) => (
                        <li key={index}>
                          <div className="icon">
                            <span className="icon-tick"></span>
                          </div>
                          <div className="text">
                            <p>{subvalue}</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="d-flex">
                    <div className="welcome-one__btn-call-box pe-3">
                      <div className="welcome-one__btn-box">
                        <a
                          data-bs-target="#equoteModal"
                          data-bs-toggle="modal"
                          href="#equoteModal"
                          className="thm-btn welcome-one__btn"
                          a="true"
                        >
                          Enquiry <i className="fa fa-angle-right"></i>
                        </a>
                      </div>
                    </div>
                    <div className="welcome-one__btn-call-box">
                      <div className="welcome-one__btn-box">
                        <a href="tel:9314512035" className="thm-btn welcome-one__btn" >
                          Call Now <i className="fa fa-angle-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <div
        className="modal fade equote"
        id="equoteModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content equote-content">
            <button
              type="button"
              className="btn-close"
              id="btn_timeout"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <h3>Enquiry</h3>
            {successMessage != "" ? (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            ) : (
              ""
            )}
            {errorMessage != "" ? (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            ) : (
              ""
            )}

            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-2">
                  <label>Full Name</label>
                  <input
                    type="text"
                    name="contact_name"
                    id="contact_name"
                    onChange={handlechangeData}
                  ></input>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <label>Email Id</label>
                  <input
                    type="email"
                    name="contact_email"
                    id="contact_email"
                    onChange={handlechangeData}
                  ></input>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <label>Phone Number</label>
                  <input
                    type="number"
                    name="contact_mobile"
                    id="contact_mobile"
                    onChange={handlechangeData}
                  ></input>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Purpose</label>
                  <textarea
                    name="contact_message"
                    id="contact_message"
                    onChange={handlechangeData}
                  ></textarea>
                </div>
              </div>

              <div className="col-lg-12">
                <div>
                  <button type="button" className="thm-btn welcome-one__btn book-but" onClick={submitmessage}>
                    <span>Enquiry</span>
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Packages;
