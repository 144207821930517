import React, { useEffect, useState, useRef } from 'react';
import {BrowserRouter as Router,Link,Route,Routes,useParams,} from "react-router-dom";
import constant from '../../Components/Services/constant';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { ApiService } from "../../Components/Services/apiservices";
import moment from 'moment';


let apiServices = new ApiService();
function BlogsCategoryWise(){
   
    const [pageData, setPageData] = useState({})
    const [pageContent, setPageContent] = useState('');
    const [headerImageUrl, setHeaderImageUrl] = useState('');
    const [blogData, setBlogData] = useState([])
    const [blogImageUrl, setBlogImageUrl] = useState('')
    const [category, setCategory] = useState('')
      
    const { slug } = useParams();
      const didMountRef = useRef(true)
    useEffect(() => {
      if (didMountRef.current) {
        const dataString={
            "page_url" : "blogs"  
        }
        apiServices.pagePostRequest(dataString).then(res=>{
        if (res.data.status === "success") {
        setPageData(res.data.pageData)
        setPageContent(res.data.pageData.page_content)
        setHeaderImageUrl(res.data.page_header_url)
        }

          const dataString2 = {
              "slug": slug
            }
            apiServices.categorywiseblogsDataPostRequest(dataString2).then(res => {
              if (res.data.status == 'success') {
                  setBlogData(res.data.blogs)
                  setCategory(res.data.categories.category_name)
                  setBlogImageUrl(res.data.base_url)
              }     
            })
        } )}
      didMountRef.current = false;
  }, [])


  return (
        <>
          <Header />

          <section className="page-header">
<div className="page-header-bg" style={{backgroundImage: `url(${headerImageUrl + pageData.page_header_image})`,backgroundSize:'cover',backgroundPosition:'100%'}}>
</div>
<div className="page-header-bubble"><img src="/assets/images/shapes/page-header-bubble.png" alt="" /></div>
<div className="container">
<div className="page-header__inner">
<ul className="thm-breadcrumb list-unstyled">
<li><a href="/">Home</a></li>
<li><span>/</span></li>
<li>Categories</li>
</ul>
<h2>{category}</h2>
</div>
</div>
</section>

<section className="blog-page">
            <div className="container">
            <div className="row">
                
                {blogData.map((value,index)=>( 
                               <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms" key={index}>
                                   <div className="blog-one__single">
                                       <div className="blog-one__img">
                                           <img src={value.blog_image != null ? blogImageUrl + value.blog_image : constant.DEFAULT_IMAGE}  alt="blog_image"/>
                                           <a href={`/blogs/${value.blog_slug}`}>
                                               <span className="blog-one__plus"></span>
                                           </a>
                                       </div>
                                       <div className="blog-one__content">
                                           <ul className="list-unstyled blog-one__meta">
                                               <li><a href={`/blogs/${value.blog_slug}`}><i className="far fa-user-circle"></i> by Admin </a>
                                               </li>
                                               <li><a href={`/blogs/${value.blog_slug}`}><i className="far fa-calendar"></i> {moment(value.created_at).format(`D MMMM`)}</a>
                                               </li>
                                           </ul>
                                           <h3 className="blog-one__title"><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h3>
                                           <div className="blog-one__btn-box">
                                               <a href={`/blogs/${value.blog_slug}`}>Read More <i className="fa fa-angle-right"></i></a>
                                           </div>
                                           <div className="blog-one__tag">
                                               <p>{value.blog_tag_name}</p>
                                           </div>
                                       </div>
                                   </div>
                               </div>
              ))} 
          </div>
            </div>
        </section> 
   
          <Footer />
        </>
    
  
  )
}
export default BlogsCategoryWise
;
