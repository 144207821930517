import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ApiService } from "../../Components/Services/apiservices";
import numeral from 'numeral';
import { validEmail, validNumber} from '../../Components/Elements/Regex';
import SpinnerLoader from "../../Components/Elements/spinnerLoader"
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
let apiServices = new ApiService();

const CorporateBookingModal = () => {
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setloader] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [bookingDetails, setBookingDetails] = useState({
    no_of_adults: 1,
    no_of_child: 0,
    booking_date: new Date().toISOString().split('T')[0],
    user_name: "",
    user_mobile: "",
    user_email: "",
    user_message: "",
    booking_type: 1,
    child_price:"",
    adult_price:""

  });  
  const handleClose = () => {
    setloader(false)
    setShow(false);
    setStep(1);
    setBookingDetails((prevState) => ({
      ...prevState,
      ['no_of_adults']: 1,
      ['no_of_child']: 0,
      ['booking_date']: new Date().toISOString().split('T')[0],
      ['user_name']: '',
      ['user_mobile']: '',
      ['user_email']: '',
      ['user_message']: '',
      ['booking_type']: 1,
      ['child_price']:'',
      ['adult_price']:''
    })); 
  } 
  useEffect(()=>{
    if(bookingDetails?.booking_date!==""){
      const datastring = {
        date : moment(bookingDetails?.booking_date.toString()).format(`YYYY-MM-DD`)
      };
      apiServices.ticketslistPostRequest(datastring).then((res) => {
        if (res.data.status == "success") {
          setBookingDetails((prevState) => ({
            ...prevState,
            child_price:res.data.ticketsdata[0]?.ticket_selling_price ,
          })); 
          setBookingDetails((prevState) => ({
            ...prevState,
            adult_price:res.data.ticketsdata[1]?.ticket_selling_price ,
          }));
        }
       
      });
    }

  },[bookingDetails?.booking_date])

  const showmodal = () => {
    setShow(true);
  };  
  const confirmBooking = () => {
    setErrorMessage("");
    if (bookingDetails.user_name === "") {
      setErrorMessage("Please enter Full Name");
      return false;
    } else if (bookingDetails.user_email === '') {
      setErrorMessage("Please enter Email Id");
      return false;
    }else if (!validEmail.test(bookingDetails.user_email)) {
      setErrorMessage("Please enter valid Email Id");
      return false;
    } else if (bookingDetails.user_mobile === "") {
      setErrorMessage("Please enter Mobile Number");
      return false;
    } else if (!validNumber.test(bookingDetails.user_mobile)) {
      setErrorMessage("Please enter valid Mobile Number");
      return false;
    }
    else if (bookingDetails.no_of_adults < 0) {
      setErrorMessage("Please enter valid No of Adult");
      return false;
    }
   else  if (bookingDetails.no_of_child < 0) {
      setErrorMessage("Please enter valid No of Child");
      return false;
    }
   
    else if (bookingDetails.no_of_child == 0 && bookingDetails.no_of_adults == 0) {
      setErrorMessage("Please enter No of Adult or No of Child");
      return false;
    }
    
    else if (bookingDetails.user_message === "") {
      setErrorMessage("Please enter message");
      return false;
    }
    

    if (bookingDetails.adult_price !== '' && bookingDetails.child_price !== ''){
      setloader(true)
      const dataString = {
        bookingDetails,
      }
      apiServices.postRequest('confirm-booking-process',dataString).then((res) => {
        if (res.data.status == "success") {
          setloader(false)
          setStep(2)
        }else{
          setloader(false)
          setErrorMessage(res.data.message)
        }
      });
    }
  
  };
  const onTodoChange = (e) => {
    setErrorMessage("")
    const { name, value } = e.target;
    setBookingDetails((prevState) => ({
      ...prevState,
      [name]: value, 
    })); 
  }; 
 

  return (
    <div>
      <a href="#" onClick={showmodal}>Bulk Bookings</a>
      {show && (
        <Modal show={show} onHide={handleClose} className="btmodal">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>

<div className="btmodal-content bookform">
            {step === 1 && (
              <div className="step-first">
                <div className="section-title">
                  <span className="section-title__tagline">Make a booking</span>
                  <h4 className="section-title__title-small">
                  Bulk Bookings at Pink Pearl Waterpark
                  </h4>
                </div>
                {successMessage !== "" ? (
                    <Alert variant="success">{successMessage}</Alert>
                ) : null}
                {errorMessage !== "" ? (
                  <Alert variant="danger">{errorMessage}</Alert> 
                ) : null}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <label>Full Name</label>
                      <input
                        type="text"
                        placeholder="Full Name"
                        name="user_name"
                        value={bookingDetails.user_name}
                        onChange={(e) => onTodoChange(e)}
                      ></input>
                    </div>
                  </div> 
                  <div className="col-lg-6">
                    <div className="form-group mb-3">
                      <label>Email Id</label>
                      <input
                        type="text"
                        placeholder="Email Id"
                        name="user_email"
                        value={bookingDetails.user_email}
                        onChange={(e) => onTodoChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-3">
                      <label>Mobile Number</label>
                      <input
                        type="number"
                        placeholder="Mobile No"
                        name="user_mobile"
                        value={bookingDetails.user_mobile}
                        onChange={(e) => onTodoChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group mb-3">
                      <label>No of Adult</label>
                      <input
                        type="number"
                        placeholder="No of Adult"
                        name="no_of_adults"
                        min="0"
                        value={bookingDetails.no_of_adults}
                        onChange={(e) => onTodoChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group mb-3">
                      <label>No of Child</label>
                      <input
                        type="number"
                        placeholder="No of Child"
                        name="no_of_child"
                        min="0"
                        value={bookingDetails.no_of_child}
                        onChange={(e) => onTodoChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group mb-3">
                      <label>Date</label>
                      <input
                        type="date"
                        placeholder="Date"
                        name="booking_date"
                        min={new Date().toISOString().split('T')[0]}
                        value={bookingDetails.booking_date}
                        onChange={(e) => onTodoChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="contact-one__form-input-box text-message-box">
                    <label>Messsage</label>
                        <textarea name="user_message" value={bookingDetails.user_message} onChange={(e) => onTodoChange(e)}></textarea>
                    </div> 
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                    <label>&nbsp;</label>
                      <button
                        className="thm-btn welcome-one__btn book-but"
                        onClick={confirmBooking}
                        disabled={loader}
                      >
                         {loader ? (
                          <img src="/assets/images/loder01.gif" width="60px" height="11px" />
                        ) : (
                          "Continue"
                        )}
                        
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )} 
            {step === 2 && (
              <div className="step-first text-center">
                <div className="section-title"> 
                    <img src="/assets/images/check-circle.gif" style={{width: '100px'}} className="mb-20"/>
                    <h2><strong>Thank You</strong></h2>
                    <h5>Your Enquiry Successfully Submitted</h5> 
                    {/* <p>Your booking ID <strong>#{orderId}</strong></p> */}
                    <p className="text-center">Please check your email for ticket booking details.</p>
                  </div>  
              </div>
            )}
          </div>
           
         
        </Modal>
      )}
    </div>
  );
};

export default CorporateBookingModal;
