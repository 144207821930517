import React, { useEffect, useRef, useState } from "react"
import { ApiService } from "../../Components/Services/apiservices";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import constant from '../../Components/Services/constant';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'; 

let apiServices = new ApiService();
function HomeGallery(){
    const [galleryData, setGalleryData] = useState([])
    const [galleryImageUrl, setGalleryImageUrl] = useState("")
    const didMountRef = useRef(true)  
    useEffect (() => {
        if(didMountRef.current){
            apiServices.galleryGetRequest().then(res => {
                if(res.data.status == "success"){
                setGalleryData(res.data.gallery)
                setGalleryImageUrl(res.data.gallery_url)
            }})
            }    
            didMountRef.current = false;
        },[])
return(
<>
<section className="project-one">
    <div className="project-one__top">
        <div className="container">
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <div className="project-one__left">
                        <div className="section-title text-left">
                            <span className="section-title__tagline">Moments of Joy</span>
                            <h2 className="section-title__title">A glimpse into all adventures</h2>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <div className="project-one__right">
                        <p className="project-one__text-1">Witness the thrilling water slides, vibrant attractions, and joyful moments that await you at Pink Pearl Water Park.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="project-one__bottom">
        <div className="project-one__bottom-container">
            <OwlCarousel
                className="owl-theme thm-owl__carousel gallery-carousel-page__carousel"
                loop={true}
                autoplay={true}
                margin={10} 
                nav={false}
                dots={true}
                smartSpeed={500}
                autoplayTimeout={2000}
                navText={["<span class='fa fa-angle-left'></span>", "<span class='fa fa-angle-right'></span>"]}
                responsive={{
                    0: { items: 1 },
                    468: { items: 2 },
                    992: { items: 3 },
                    1200: { items: 5 }
                }} >
                {galleryData.map((value,index)=> {
                    return (
                    <div className="item" key={index}>
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src={value.gallery_image != null ? galleryImageUrl + value.gallery_image : constant.DEFAULT_IMAGE} alt="gallery_image" />
                                <div className="gallery-page__icon">
                                    <a className="img-popup galerrydata" href={value.gallery_image != null ? galleryImageUrl + value.gallery_image : constant.DEFAULT_IMAGE} alt="gallery_image"><span
                                    className="icon-plus-symbol"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                })}
            </OwlCarousel>
         </div>
    </div>
</section>

</>
)}
export default HomeGallery;