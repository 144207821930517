import React, { createContext, useState } from 'react';
const DataContext = createContext();

export const DataProvider = ({ children }) => {

  const [settingData, setSettingData] = useState({});
  const [settingImageBaseUrl, setSettingImageBaseUrl] = useState({});


    return (
        <DataContext.Provider value={
          {
            settingData, setSettingData,
            settingImageBaseUrl, setSettingImageBaseUrl,
          }
        }>
          {children}
        </DataContext.Provider>
      );
    };
export default DataContext;