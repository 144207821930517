import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import TicketCommonModal from "../TicketCommonModal";
import moment from 'moment';

let apiServices = new ApiService();
function HomeTickets() {
  const [ticketsData, setTicketsData] = useState([]);
  const [range,setrange]=useState([])
  const didMountRef = useRef(true);
  const current_Date = new Date()
  useEffect(() => {
    if (didMountRef.current) {
      const dataString={
        date:moment(current_Date.toString()).format(`YYYY-MM-DD`)
      }
      apiServices.ticketslistPostRequest(dataString).then((res) => {
        if (res.data.status == "success") {
          setTicketsData(res.data.ticketsdata);
          // setrange(8)
        }
   
      });
      didMountRef.current = false;
    }
  });
      console.log("range",ticketsData)
  return (
    <>
      <section className="process">
        <div className="container">
        <div className="section-title text-center" style={{padding : "30px"}}>
            <span className="section-title__tagline mt-10" >What We’re Offering</span>
            <h2 className="section-title__title">
            Our Handpicked Offers   <br />
            Waiting For You!
            </h2>
          </div>
          <div className="row">
            {/* <div className="col-lg-4">
              <div className="section-title">
                <span className="section-title__tagline">Our packages</span>
                <h2 className="section-title__title">
                  Choose The Best Packages
                </h2>
                <p>
                  Let the water splashes bring happiness in abundance while you
                  have fun at Pink Pearl Fun City and Water Park.
                </p>
              </div>
            </div> */}
            <div className="col-lg-12">
              <div className="row">
                {ticketsData.map((value, index) => (
                  <div className="col-lg-4" key={index}>
                    <div className="packagebox mb-3">
                      <div className="packagebox-header">
                        <h2>
                          <strong>{value.ticket_title}</strong>
                        </h2>
                        <p>{value.ticket_subtitle}</p>
                      </div>
                      <div className="packagebox-price mt-3">
                        <p style={{ display: 'inline-block', marginRight: '8px' }}>Adults:</p>
                        ₹{value.adult_ticket_price}
                        <span>/person</span>
                      </div>
                      <div className="packagebox-price">
                        <p className="mb-4"style={{ display: 'inline-block', marginRight: '8px' }}>Kids:</p>
                        ₹{value.kid_ticket_price}
                        <span>/person</span>
                      </div>
                      {value.ticket_highlight.split('##').map((highlight, index) => (
                                          <span key={index}>
                                            {highlight}
                                            <br />
                                          </span>
                                        ))}
                        <p
                        dangerouslySetInnerHTML={{ __html: value.ticket_desc }}
                        ></p>
                      <TicketCommonModal range={range} ticketId={value.ticket_id}/>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      ;
    </>
  );
}
export default HomeTickets;
