import React, { useEffect, useState, useRef } from "react";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";

let apiServices = new ApiService();
function SidebarDetails() {
  const [blogsData, setBlogsData] = useState([]);
  const [blogUrl, setBlogUrl] = useState("");
  const [blogsCategoryData, setBlogsCategoryData] = useState([]);
  const [blogsTagData, setBlogsTagData] = useState([]);

  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      apiServices.getfeaturedblogs().then((res) => {
        if (res.data.status == "success") {
          setBlogsData(res.data.blogsData);
          setBlogUrl(res.data.blog_url);
        }
      });

      apiServices.blogCategoryDataGetRequest().then((res) => {
        if (res.data.status == "success") {
          setBlogsCategoryData(res.data.categories);
        }
      });

      apiServices.blogTagsDataGetRequest().then((res) => {
        if (res.data.status == "success") {
          setBlogsTagData(res.data.tags);
        }
      });

      didMountRef.current = false;
    }
  });

  return (
    <>
      <div className="sidebar">
        <div className="sidebar__single sidebar__post">
          <h3 className="sidebar__title">Latest Posts</h3>
          <ul className="sidebar__post-list list-unstyled">
            {blogsData?.map((value, index) => (
              <li key={index}>
                <div className="sidebar__post-image" >
                  <img src={ value.blog_image != null ? blogUrl + value.blog_image : constant.DEFAULT_IMAGE} alt="image"/>
                </div>
                <div className="sidebar__post-content">
                  <h3>
                    <span className="sidebar__post-content-meta">
                      <i className="far fa-user-circle"></i> by Admin
                    </span>
                    <a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a>
                  </h3>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="sidebar__single sidebar__category">
          <h3 className="sidebar__title">Categories</h3>
          <ul className="sidebar__category-list list-unstyled">
            {blogsCategoryData.map((value, index) => (
              <li key={index}>
                <a href={"/blogs/category/" + value.category_slug}>
                  {value.category_name}
                  <span className="fa fa-angle-right"></span>
                </a>
              </li>
            ))}
          </ul>
        </div>

        {blogsTagData.length > 0 ? (
          <div className="sidebar__single sidebar__tags">
            <h3 className="sidebar__title">Tags</h3>
            <div className="sidebar__tags-list">
              {blogsTagData.map((value) => (
                <a key={value.tag_slug} href={"/blogs/tags/" + value.tag_slug}>{value.tag_name}</a>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
export default SidebarDetails;
