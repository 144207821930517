import React, { useRef, useState,useContext } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { useEffect } from 'react'
import { ApiService } from '../../Components/Services/apiservices';
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from 'react-helmet';
import { Autoplay, Navigation } from 'swiper';
import constant from '../../Components/Services/constant';
import DataContext from '../../Components/Elements/eventContext';

let apiServices = new ApiService();
function Wedding() {
  const contextValues = useContext(DataContext);
  const didMountRef = useRef(true);
  const [pageData, setPageData] = useState({});
  const [pageContent, setPageContent] = useState("");
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [settingData, setSettingData] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [showFullTextWedding, setShowFullTextWedding] = useState(false);
  const [showFullTextRestaurant, setShowFullTextRestaurant] = useState(false);
  const [showFullTextBanquet, setShowFullTextBanquet] = useState(false);


  useEffect(() => {

    if (didMountRef.current) {
      getPageData()
      // geSettingdata()
    }
    didMountRef.current = false
  })

  const getPageData = () => {

    const dataString = {
      page_url: "wedding",
    };
    apiServices.pagePostRequest(dataString).then((res) => {
      if (res.data.status === "success") {
        setPageData(res.data.pageData);
        setPageContent(res.data.pageData.page_content);
        setHeaderImageUrl(res.data.page_header_url);
      }
    });


  }

  const geSettingdata = () => {

    apiServices.settingsdataRequest().then((res) => {
      if (res.data.status == "success") {
        setSettingData(res.data.sitesettings);
        setImageUrl(res.data.settings_url);
      }
    });
  }

  const handleReadMore = (section) => {
    if (section === "wedding") {
      setShowFullTextWedding(!showFullTextWedding);
    } else if (section === "restaurant") {
      setShowFullTextRestaurant(!showFullTextRestaurant);
    } else if (section === "banquet") {
      setShowFullTextBanquet(!showFullTextBanquet);
    }
  }

  return (
    <>
      <Helmet>
      <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Pink Pearl"}</title>
      <meta
          name="description"
          itemprop="description"
          content={pageData?.page_meta_desc != null ? pageData?.page_meta_desc :"Pink Pearl"}
        />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Pink Pearl" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Pink Pearl" />
        <meta property="og:url" content={window.location.href} />
        {pageData?.page_meta_desc != null ? (
            <meta property="og:description" content={pageData?.page_meta_desc } />
            ) : (
            <meta property="og:description" content="Pink Pearl" />
            )}
        {pageData?.page_header_image ? (
                  <meta property="og:image" content={headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                    property="og:image"
                    content={constant.FRONT_URL + 'assets/images/logo.png'}
                  />
                )}
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Pink Pearl" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        {pageData?.page_meta_desc != null ? (
                    <meta property="twitter:description" content={pageData?.page_meta_desc} />
                ) : (
                    <meta property="twitter:description" content="Pink Pearl" />
                )}
        {pageData?.page_header_image ? (
                  <meta property="twitter:image" content={ headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                  property="twitter:image"
                    content={constant.FRONT_URL + 'assets/images/logo.png'}
                  />
                )}
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>
      <Header />
      <section className="page-header">
        <div className="page-header-bg" style={{ backgroundImage: `url(${headerImageUrl + pageData.page_header_image})`, backgroundSize: 'cover', backgroundPosition: '100%' }}>
        </div>
        <div className="page-header-bubble"><img src="/assets/images/shapes/page-header-bubble.png" alt="" /></div>
        <div className="container">
          <div className="page-header__inner">

            {pageData.page_header != null ? <h1>{pageData.page_header}</h1> : ""}
            <ul className="thm-breadcrumb list-unstyled">
              <li><a href="/">Home</a></li>
              <li><span>/</span></li>
              {pageData.page_name != null ? <li>{pageData.page_name}</li> : ""}
            </ul>
          </div>
        </div>
      </section>
      <section className="call-one accomadation-yop">
        <div className="call-one-shape-1" style={{ backgroundImage: `url(https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707122111.png)` }}></div>

        <div className="call-one-shape-2" style={{ backgroundImage: `url(https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707122076.png)` }}></div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="call-one__inner text-center" style={{ justifyContent: 'center' }}>
                <div className="call-one__text-box">
                  <p className="call-one__text">Pink Pearl Wedding Garden, Jaipur</p>

                  <p style={{ color: "#fff" }}>{contextValues.settingData.address}</p>

                  <div className="address-tel">
                    <div className="telnumber"><a href={"tel:" + contextValues.settingData.admin_support_mobile}>{contextValues.settingData.admin_support_mobile}</a></div>

                    <div className="mailnumber"><a href={"mailto:" + contextValues.settingData.admin_email}>{contextValues.settingData.admin_email}</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="section-title__title">Overview</h2>
          </div>

          <div className="row">
            <div className="col-lg-4"><img className="mb-2" src="	https://pinkpearl.co.in/csadmin/public/img/uploads/media/1710412319.jpg" width="100%" /></div>

            <div className="col-lg-4"><img className="mb-2" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1710412324.jpg" width="100%" /></div>

            <div className="col-lg-4"><img className="mb-2" src="	https://pinkpearl.co.in/csadmin/public/img/uploads/media/1710412329.jpg" width="100%" /></div>
          </div>

          <div className="row text-center mt-4">
            <div className="col-lg-12"><p className="mb-2">Known around the world among the top <span  style={{color: "#4bbed1"}}>Wedding Venues</span>, the state of Rajasthan contains everything for an ideal location. Pink Pearl features stunning Halls and <span style={{color: "#4bbed1"}}>Lawns for Weddings</span> for both large and small occasions. Alongside first className catering and event planning services we ensure your special day is well worth the investment. Nestled amidst the vibrant city of Jaipur, Pink Pearl offers a stunning backdrop for your once-in-a-lifetime celebration.
            </p>

              <p className="mb-2">We provide luxurious accommodations, i.e., rooms and suites, for your guests with stunning views of our <span style={{color: "#4bbed1"}}>Marriage Garden in Jaipur.</span> We also offer both indoor and outdoor locations for a variety of receptions and ceremonies. Our dedicated team of experts handle each aspect of your wedding with care and passion to ensure a tranquil and peaceful environment around the event.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order2">
              <div className="section-title text-left">
                <h2 className="section-title__title">Wedding Garden</h2>
              </div>

              <p className="mb-4">Accommodating up to 1,000 guests, the <span style={{color: "#4bbed1"}}>Wedding Lawn</span> offers ample space for large events in Jaipur. Complete with lush greenery and well-manicured lawns spread over a large area, it makes a perfect venue for a <span style={{color: "#4bbed1"}}>‘Marriage Garden Near Me’.</span></p>

              <p className="mb-2">The plush furnishings, spacious background, and royal decor put Pink Pearl among the best hotels in Jaipur. Modern amenities are provided for convenience along with personalized service to make the stay relaxing. The curtains reveal unparalleled views of the landscape to offer a visual treat like no other.</p>
              <div className='buttonacgroup'>
                <a className="thm-btn welcome-one__btn mt-2" href="/book-tickets" style={{ marginRight: "30px" }}>Book Now <i className="fa fa-angle-right"></i></a>
                <a className="thm-btn welcome-one__btn mt-2" href="#hotelamenities">Room Details<i className="fa fa-angle-right"></i></a>
              </div>
            </div>

            <div className="col-lg-6 order1">
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Navigation]}
                navigation={true}
              >
                <SwiperSlide>
                  <img className="mb-4" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1710412223.jpg" width="100%" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="mb-4" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1710764890.jpg" width="100%" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order2">
              <div className="section-title text-left">
                <h2 className="section-title__title">Banquet Hall</h2>
              </div>

              <p className="mb-4">State-of-the-art Banquet Hall makes an ideal area for seating, dining, and dancing seating up to 200 guests. Personalize your events with our expert planning services; we also offer modern amenities to serve <span style={{color: "#4bbed1"}}>Wedding Places in Jaipur.</span></p>

              
              <div className='buttonacgroup'>
                <a className="thm-btn welcome-one__btn mt-2" href="/book-tickets" style={{ marginRight: "30px" }}>Book Now <i className="fa fa-angle-right">&nbsp;</i></a>
                <a className="thm-btn welcome-one__btn mt-2" href="#hotelamenities">Room Details<i className="fa fa-angle-right">&nbsp;</i></a>
              </div>

            </div>

            <div className="col-lg-6 order1">
              <Swiper
                loop={true}
                spaceBetween={0}
                slidesPerView={1}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Navigation]}
                navigation={true}

              >
                <SwiperSlide>
                  <img className="mb-4" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1710412451.jpg" width="100%" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="mb-4" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1710764902.jpg" width="100%" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding pt-0">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="section-title__title">Highlights</h2>
          </div>

          <div className="row">
            <div className="col-lg-4"><img className="mb-2" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1710410954.jpg" width="100%" />
              <h3 style={{ textAlign: "center", color: "#fff", backgroundColor: "#ffb400", borderColor: "#ffb400", borderRadius: "5px", padding: "15px 0px" }} className='mb-3'>Entrance</h3>

              <p className="mb-4">
                {showFullTextWedding ? (
                  <>
                    A one-of-a-kind setting that is steeped in cultural heritage and natural beauty. Celebrations at Pink Pearl intertwine timeless customs with luxurious comfort for a memorable experience. Adorned with intricate designs and decorated using fragrant flowers, we make sure your wedding is a colorful and exciting endeavor. Our dedicated team of experts ensure your cultural preferences are woven into every aspect of your celebration. From incorporating traditional rituals into the ceremony to offering a curated menu, we assure to reflect your style into the wedding.
                    <br />
                    <div style={{ fontWeight: "bold" }} onClick={() => handleReadMore("wedding")}><a href='javascript:void(0)' style={{ color: "#4bbed1" }} >Read Less</a></div>
                  </>
                ) : (
                  <>
                    A one-of-a-kind setting that is steeped in cultural heritage and natural beauty. Celebrations at Pink Pearl...
                    <br />
                    <div style={{ fontWeight: "bold" }} onClick={() => handleReadMore("wedding")}><a href='javascript:void(0)' style={{ color: "#4bbed1" }}>Read More</a></div>
                  </>
                )}

              </p>
            </div>

            <div className="col-lg-4"><img className="mb-2" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1710411025.jpg" width="100%" />
              <h3 style={{ textAlign: "center", color: "#fff", backgroundColor: "#ffb400", borderColor: "#ffb400", borderRadius: "5px", padding: "15px 0px" }} className='mb-3'>Wedding Garden</h3>

              <p className="mb-4">
                {showFullTextRestaurant ? (
                  <>
                    The regal architecture of Pink Pearl inspired by Rajasthani royalty and luxurious ambiance make <span style={{ color: "#4bbed1" }}>Wedding Venues in Jaipur</span> a perfect backdrop. A large and natural Wedding Lawn graces our space for any type of events with a seating capacity of up to 1000 guests. Customize our <span style={{ color: "#4bbed1" }}>Celebration Garden</span> to suit your preferences and specific needs as you like. A wide range of savory delights are available to serve the guests and please every palate. Create a memorable wedding in a comfortable setting for you and your guests.
                    <br />
                    <div style={{ fontWeight: "bold" }} onClick={() => handleReadMore("restaurant")}><a href='javascript:void(0)' style={{ color: "#4bbed1" }}>Read Less</a></div>

                  </>
                ) : (
                  <>
                    The regal architecture of Pink Pearl inspired by Rajasthani royalty and luxurious ambiance make <span style={{ color: "#4bbed1" }}>Wedding Venues in Jaipur</span> a perfect backdrop...
                    <br />
                    <div style={{ fontWeight: "bold" }} onClick={() => handleReadMore("restaurant")}><a href='javascript:void(0)' style={{ color: "#4bbed1" }}> Read More</a></div>
                  </>
                )}
              </p>
            </div>

            <div className="col-lg-4"><img className="mb-2" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1710411030.jpg" width="100%" />
              <h3 style={{ textAlign: "center", color: "#fff", backgroundColor: "#ffb400", borderColor: "#ffb400", borderRadius: "5px", padding: "15px 0px" }} className='mb-3'>Decor</h3>

              <p className="mb-4">
                {showFullTextBanquet ? (
                  <>
            Celebrate your love story in the royal city of Jaipur, amidst the rich hues of the Pink City, coupled with the vibrant culture and immersive cuisines at Pink Pearl Resort. Featuring royal halls, exquisite decorations, and modern amenities, we offer a place where grand celebrations take on a new life. We perfectly capture the magic of your wedding amidst picturesque gardens and decorations, to suit your dream wedding vision. We ensure every detail is meticulously taken care of, allowing you to relax and enjoy your special day.
                    <br />
                    <div style={{ fontWeight: "bold" }} onClick={() => handleReadMore("banquet")}><a href='javascript:void(0)' style={{ color: "#4bbed1" }}>Read Less</a></div>

                  </>
                ) : (
                  <>
                   Celebrate your love story in the royal city of Jaipur, amidst the rich hues of the Pink City, coupled with the vibrant culture....
                    <br />
                    <div style={{ fontWeight: "bold" }} onClick={() => handleReadMore("banquet")}><a href='javascript:void(0)' style={{ color: "#4bbed1" }}>Read More</a></div>
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding pt-0">
        <div className="container ">
          <div className="row g-2">
            <div className="col-lg-2 col-6">
              <div className='acdapartbox'>
                <img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707213797.png" />
              </div>
            </div>

            <div className="col-lg-2 col-6"><div className='acdapartbox'><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707213827.jpg" /></div></div>

            <div className="col-lg-2 col-6"><div className='acdapartbox'><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707213912.png" /></div></div>

            <div className="col-lg-2 col-6"><div className='acdapartbox'><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707213931.png" /></div></div>

            <div className="col-lg-2 col-6"><div className='acdapartbox'><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707213947.png" /></div></div>

            <div className="col-lg-2 col-6"><div className='acdapartbox'><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707213963.jpg" /></div></div>
          </div>
        </div>
      </section>

      <section id="hotelamenities" className="section-padding pt-0">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="section-title__title">Hotel Amenities</h2>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="amitlist">
                <ul>
                  <li><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707127218.png" />Accessible hotel near Jaipur railway station</li>
                  <li><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707127361.png" />Conveniently located hotel on Jaipur Ajmer highway</li>
                  <li><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707127365.png" />In-house multi-cuisine restaurant</li>
                  <li><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707127635.png" />Complimentary Wi-Fi</li>
                  <li><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707133880.png" />Air-conditioned rooms</li>
                  <li><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707133655.png" />Flat-screen TVs</li>
                  <li><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707133768.png" />24-hour front desk service</li>
                  <li><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707127489.png" />Spacious halls</li>
                  <li><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707133832.png" />Natural surroundings</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section>&nbsp;</section>
      </section>


      <Footer />

    </>
  )
}

export default Wedding