import React, { useRef, useState,useContext } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { useEffect } from 'react'
import { ApiService } from '../../Components/Services/apiservices';
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from 'react-helmet';
import { Autoplay,Navigation } from 'swiper';
import constant from '../../Components/Services/constant';
import DataContext from '../../Components/Elements/eventContext';


let apiServices = new ApiService();
function Accommodations() {

  const didMountRef = useRef(true);
  const [pageData, setPageData] = useState({});
  const contextValues = useContext(DataContext);
  const [pageContent, setPageContent] = useState("");
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [settingData, setSettingData] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [showFullTextWedding, setShowFullTextWedding] = useState(false);
  const [showFullTextRestaurant, setShowFullTextRestaurant] = useState(false);
  const [showFullTextBanquet, setShowFullTextBanquet] = useState(false);


  useEffect(() => {

    if (didMountRef.current) {
      getPageData()
      // geSettingdata()
    }
    didMountRef.current = false
  })

  const getPageData = () => {

    const dataString = {
      page_url: "accommodations",
    };
    apiServices.pagePostRequest(dataString).then((res) => {
      if (res.data.status === "success") {
        setPageData(res.data.pageData);
        setPageContent(res.data.pageData.page_content);
        setHeaderImageUrl(res.data.page_header_url);
      }
    });


  }

  const geSettingdata = () =>{

    apiServices.settingsdataRequest().then((res) => {
      if (res.data.status == "success") {
        setSettingData(res.data.sitesettings);
        setImageUrl(res.data.settings_url);
      }
    });
  }

const handleReadMore = (section) => {
  if (section === "wedding") {
    setShowFullTextWedding(!showFullTextWedding);
  } else if (section === "restaurant") {
    setShowFullTextRestaurant(!showFullTextRestaurant);
  } else if (section === "banquet") {
    setShowFullTextBanquet(!showFullTextBanquet);
  }
}

  return (
    <>
      <Helmet>
      <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Pink Pearl"}</title>
      <meta
          name="description"
          itemprop="description"
          content={pageData?.page_meta_desc != null ? pageData?.page_meta_desc :"Pink Pearl"}
        />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Pink Pearl" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Pink Pearl" />
        <meta property="og:url" content={window.location.href} />
        {pageData?.page_meta_desc != null ? (
            <meta property="og:description" content={pageData?.page_meta_desc } />
            ) : (
            <meta property="og:description" content="Pink Pearl" />
            )}
        {pageData?.page_header_image ? (
                  <meta property="og:image" content={headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                    property="og:image"
                    content={constant.FRONT_URL + 'assets/images/logo.png'}
                  />
                )}
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Pink Pearl" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        {pageData?.page_meta_desc != null ? (
                    <meta property="twitter:description" content={pageData?.page_meta_desc} />
                ) : (
                    <meta property="twitter:description" content="Pink Pearl" />
                )}
        {pageData?.page_header_image ? (
                  <meta property="twitter:image" content={ headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                  property="twitter:image"
                    content={constant.FRONT_URL + 'assets/images/logo.png'}
                  />
                )}
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>
      <Header />
      <section className="page-header">
        <div className="page-header-bg" style={{ backgroundImage: `url(${headerImageUrl + pageData.page_header_image})`, backgroundSize: 'cover', backgroundPosition: '100%' }}>
        </div>
        <div className="page-header-bubble"><img src="/assets/images/shapes/page-header-bubble.png" alt="" /></div>
        <div className="container">
          <div className="page-header__inner">

            {pageData.page_header != null ? <h1>{pageData.page_header}</h1> : ""}
            <ul className="thm-breadcrumb list-unstyled">
              <li><a href="/">Home</a></li>
              <li><span>/</span></li>
              {pageData.page_name != null ? <li>{pageData.page_name}</li> : ""}
            </ul>
          </div>
        </div>
      </section>
      <section className="call-one accomadation-yop">
        <div className="call-one-shape-1" style={{ backgroundImage: `url(https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707122111.png)` }}></div>

        <div className="call-one-shape-2" style={{ backgroundImage: `url(https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707122076.png)` }}></div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="call-one__inner text-center" style={{ justifyContent: 'center' }}>
                <div className="call-one__text-box">
                  <p className="call-one__text">Pink Pearl Hotel, Jaipur</p>

                  <p style={{ color: "#fff" }}>{contextValues.settingData.address}</p>

                  <div className="address-tel">
                    <div className="telnumber"><a href={"tel:" + contextValues.settingData.admin_support_mobile}>{contextValues.settingData.admin_support_mobile}</a></div>

                    <div className="mailnumber"><a href={"mailto:" + contextValues.settingData.admin_email}>{contextValues.settingData.admin_email}</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="section-title__title">Overview</h2>
          </div>

          <div className="row">
            <div className="col-lg-4"><img className="mb-2" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707214339.jpg" width="100%" /></div>

            <div className="col-lg-4"><img className="mb-2" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707214356.jpg" width="100%" /></div>

            <div className="col-lg-4"><img className="mb-2" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707214372.jpg" width="100%" /></div>
          </div>

          <div className="row text-center mt-4">
            <div className="col-lg-12">
              <p className="mb-2">With luxurious accommodations and breathtaking landscapes, Pink Pearl offers a premier destination for travelers and visitors looking for an escape from the common and into the adventurous. Nestled in the heart of the &ldquo;Pink City&rdquo;, the <strong><a href='https://pinkpearl.co.in/hotel' style={{color: "#4bbed1"}}>5 star hotels in Jaipur</a></strong> combine comfort, elegance, and natural beauty to provide an unforgettable experience.</p>

              <p className="mb-2">Equipped with unparalleled amenities that cater to your every need, the resort brings the best of both the royal era and the modern world. With over 33 nicely furnished suites and rooms featuring amenities, including flat-screen TVs, air conditioning, complimentary Wi-Fi, and a 24-hour front desk service, the resort ensures guests have a convenient and hassle-free stay.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order2">
              <div className="section-title text-left">
                <h2 className="section-title__title">Deluxe Rooms</h2>
              </div>

              <p className="mb-4">As you enter our Deluxe Rooms, you are met with tranquil surroundings that immerse you in the ambiance of the Jaipur Ajmer highway resorts.</p>

              <p className="mb-2">The plush furnishings, spacious background, and royal decor put Pink Pearl among the best hotels in Jaipur. Modern amenities are provided for convenience along with personalized service to make the stay relaxing. The curtains reveal unparalleled views of the landscape to offer a visual treat like no other.</p>
              <div className='buttonacgroup'>
              <a className="thm-btn welcome-one__btn mt-2" href="/book-tickets" style={{marginRight:"30px"}}>Book Now <i className="fa fa-angle-right"></i></a>
              <a className="thm-btn welcome-one__btn mt-2" href="#hotelamenities">Room Details<i className="fa fa-angle-right"></i></a>
              </div>
              </div>
         
            <div className="col-lg-6 order1">
            <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    autoplay={{
                      delay: 1500,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay,Navigation]}
                    navigation={true}
                  >
                    <SwiperSlide>
                       <img className="mb-4" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707214750.jpg	" width="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="mb-4" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707214750.jpg	" width="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="mb-4" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707214750.jpg	" width="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="mb-4" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707214750.jpg	" width="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="mb-4" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707214750.jpg	" width="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="mb-4" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707214750.jpg	" width="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="mb-4" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707214750.jpg	" width="100%" />
                    </SwiperSlide>
                  </Swiper>
              </div>
          </div>
        </div>
      </section>

      <section className="section-padding pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order2">
              <div className="section-title text-left">
                <h2 className="section-title__title">Executive Rooms</h2>
              </div>

              <p className="mb-4">Unwind in executive rooms designed for relaxation amidst lush surroundings and breathtaking views. Equipped with modern amenities, this room offers 24-hour service to help guests with any type of service.</p>

              <p className="mb-2">The spacious indoors makes for a comfortable stay. With a perfect blend of grandeur and functionality, the room caters to your every need. Our rooms also put Pink Pearl among the best hotels in Jaipur for family visits to soak in the vibrancy of royal heritage.</p>
              <div className='buttonacgroup'>
              <a className="thm-btn welcome-one__btn mt-2" href="/book-tickets" style={{marginRight:"30px"}}>Book Now <i className="fa fa-angle-right">&nbsp;</i></a>
              <a className="thm-btn welcome-one__btn mt-2" href="#hotelamenities">Room Details<i className="fa fa-angle-right">&nbsp;</i></a>
              </div>
             
              </div>

            <div className="col-lg-6 order1">
            <Swiper
            loop={true}
                    spaceBetween={0}
                    slidesPerView={1}
                    autoplay={{
                      delay: 1500,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay,Navigation]}
                    navigation={true}

                  >
                    <SwiperSlide>
                    <img className="mb-4" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707214668.jpg" width="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="mb-4" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707214668.jpg" width="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="mb-4" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707214668.jpg" width="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="mb-4" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707214668.jpg" width="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="mb-4" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707214668.jpg" width="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="mb-4" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707214668.jpg" width="100%" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="mb-4" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707214668.jpg" width="100%" />
                    </SwiperSlide>
                  </Swiper>
              </div>
          </div>
        </div>
      </section>

      <section className="section-padding pt-0">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="section-title__title">Hotel Highlights</h2>
          </div>

          <div className="row">
          <div className="col-lg-4"><img className="mb-2" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707300794.jpg" width="100%" />
          <a href='/wedding'>
              <h3 style={{ textAlign: "center" ,color: "#fff",backgroundColor: "#ffb400",borderColor: "#ffb400",borderRadius:"5px",padding: "15px 0px"}} className='mb-3'>Wedding</h3></a>

              <p className="mb-4">
              {showFullTextWedding ? (
    <>
        The Wedding Garden at Pink Pearl Hotel in Ajmer Road is a stunning outdoor venue with a capacity of 1000 pax. Situated in a picturesque setting at the Ajmer Jaipur highway, the garden is spread over a large area and features lush greenery and well-manicured lawns. It is a beautiful haven created to bring your fairytale wedding to life. It provides ample space for seating, dining, and dancing, making it an ideal location for both intimate gatherings and grand celebrations. This Ajmer Road Hotel Jaipur also offers personalized event planning services and modern amenities to ensure an unforgettable event.
        <br />
        <div style={{fontWeight: "bold"}} onClick={() => handleReadMore("wedding")}><a href='javascript:void(0)'  style={{color: "#4bbed1"}} >Read Less</a></div>
    </>
) : (
    <>
        The Wedding Garden at Pink Pearl Hotel in Ajmer Road is a stunning outdoor venue with a capacity of 1000 pax.
        Situated in a picturesque setting at the Ajmer Jaipur highway, the garden is spread over a large area and features...
        <br />
        <div style={{fontWeight: "bold"}} onClick={() => handleReadMore("wedding")}><a href='javascript:void(0)' style={{color: "#4bbed1"}}>Read More</a></div>
    </>
)}

              </p>
            </div>

            <div className="col-lg-4"><img className="mb-2" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707300870.jpg" width="100%" />
              <h3 style={{ textAlign: "center", color: "#fff",backgroundColor: "#ffb400",borderColor: "#ffb400",borderRadius:"5px",padding: "15px 0px"}} className='mb-3'>Restaurant</h3>

              <p className="mb-4">
              {showFullTextRestaurant ? (
                      <>
                 The restaurant provides a fine, elegant, and cozy dining experience, perfect for 
              intimate gatherings and events. The menu features a range of gourmet dishes, expertly crafted by 
              our skilled chefs in the kitchen making sure every bite is a delight. One of the best luxury hotels in Jaipur, 
              Pink Pearl makes sure every guest gets a delightful experience with their meals. The staff at the restaurant
               pays exceptional attention to detail and offers great service, ensuring all guests get a memorable experience
                at the best hotel at Ajmer Jaipur highway. Pink Pearl also prides itself on its commitment to sustainability
                 by using locally sourced ingredients and reducing food waste wherever possible.
                 <br />
        <div style={{fontWeight: "bold"}} onClick={() => handleReadMore("restaurant")}><a href='javascript:void(0)' style={{color: "#4bbed1"}}>Read Less</a></div>

                 </>
                  ) : (
                      <>
                The restaurant provides a fine, elegant, and cozy dining experience, perfect for 
              intimate gatherings and events. The menu features a range of gourmet dishes, expertly crafted by 
              our skilled chefs in the kitchen making sure every bite...
              <br />
                          <div style={{fontWeight: "bold"}} onClick={() => handleReadMore("restaurant")}><a href='javascript:void(0)' style={{color: "#4bbed1"}}> Read More</a></div>
                 </>
                  )}
                 </p>
            </div>

            <div className="col-lg-4"><img className="mb-2" src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707300839.jpg" width="100%" />
              <h3 style={{ textAlign: "center" ,color: "#fff",backgroundColor: "#ffb400",borderColor: "#ffb400",borderRadius:"5px",padding: "15px 0px"}} className='mb-3'>Banquet</h3>

              <p className="mb-4">
              {showFullTextBanquet ? (
                      <>
                Offering a seating capacity of up to 200 guests, our banquet is an ideal space for a diverse range of occasions,
               from weddings to corporate events to social gatherings. The impeccable service, stunning view, and dedication to quality 
               help set the stage for a memorable event at the top hotels in Jaipur Ajmer. The spacious area and sophisticated setting
                of our venue add elegance to the whole affair. The hotel highway is equipped with modern amenities and can also be 
                customized to your specific needs and preferences, with a range of menu options available to suit every taste. 
                Guests can also get comfortable in the fully air-conditioned room while they indulge in their respective occasions.
                <br />
        <div style={{fontWeight: "bold"}} onClick={() => handleReadMore("banquet")}><a href='javascript:void(0)' style={{color: "#4bbed1"}}>Read Less</a></div>

                 </>
                  ) : (
                      <>
                 Offering a seating capacity of up to 200 guests, our banquet is an ideal space for a diverse range of occasions,
               from weddings to corporate events to social gatherings. The impeccable service, stunning view, and dedication to quality 
               help....
                <br />
                          <div style={{fontWeight: "bold"}} onClick={() => handleReadMore("banquet")}><a href='javascript:void(0)' style={{color: "#4bbed1"}}>Read More</a></div>
                 </>
                  )}
                </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding pt-0">
        <div className="container ">
          <div className="row g-2">
            <div className="col-lg-2 col-6">
              <div className='acdapartbox'>
              <img  src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707213797.png" />
              </div>
              </div>

            <div className="col-lg-2 col-6"><div className='acdapartbox'><img  src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707213827.jpg" /></div></div>

            <div className="col-lg-2 col-6"><div className='acdapartbox'><img  src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707213912.png" /></div></div>

            <div className="col-lg-2 col-6"><div className='acdapartbox'><img  src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707213931.png" /></div></div>

            <div className="col-lg-2 col-6"><div className='acdapartbox'><img  src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707213947.png" /></div></div>

            <div className="col-lg-2 col-6"><div className='acdapartbox'><img  src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707213963.jpg" /></div></div>
          </div>
        </div>
      </section>

      <section id="hotelamenities" className="section-padding pt-0">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="section-title__title">Hotel Amenities</h2>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="amitlist">
                <ul>
                  <li><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707127218.png" />Accessible hotel near Jaipur railway station</li>
                  <li><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707127361.png" />Conveniently located hotel on Jaipur Ajmer highway</li>
                  <li><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707127365.png" />In-house multi-cuisine restaurant</li>
                  <li><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707127635.png" />Complimentary Wi-Fi</li>
                  <li><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707133880.png" />Air-conditioned rooms</li>
                  <li><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707133655.png" />Flat-screen TVs</li>
                  <li><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707133768.png" />24-hour front desk service</li>
                  <li><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707127489.png" />Spacious halls</li>
                  <li><img src="https://pinkpearl.co.in/csadmin/public/img/uploads/media/1707133832.png" />Natural surroundings</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section>&nbsp;</section>
      </section>


      <Footer />

    </>
  )
}

export default Accommodations