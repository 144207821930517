import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { ApiService } from "../../Components/Services/apiservices";
import constant from "../../Components/Services/constant";
import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Helmet } from "react-helmet";
import SidebarDetails from "../SidebarDetails";

let apiServices = new ApiService();

function BlogsDetails() {
  const [pageData, setPageData] = useState({});
  const [pageContent, setPageContent] = useState("");
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [blogDetailData, setBlogDetailData] = useState({});
  const [blogDetailImageUrl, setBlogDetailImageUrl] = useState("");
  const [categoryData, setCategoryData] = useState([]);

  const { slug } = useParams();
  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
        page_url: "blogs",
      };
      apiServices.pagePostRequest(dataString).then((res) => {
        if (res.data.status === "success") {
          setPageData(res.data.pageData);
          setPageContent(res.data.pageData.page_content);
          setHeaderImageUrl(res.data.page_header_url);
        }
        const dataString2 = {
          blog_slug: slug,
        };
        apiServices.blogdetailsPostRequest(dataString2).then((res) => {
          if (res.data.status === "success") {
            setBlogDetailData(res.data.blogDetails);
            setBlogDetailImageUrl(res.data.blog_url);
            setCategoryData(res.data.categoryData);
          }
        });
      });
    }
    didMountRef.current = false;
  });
  return (
    <>
      <Helmet>
      <title>{blogDetailData?.blog_meta_title != null ? blogDetailData.blog_meta_title : "Pink Pearl"}</title>
      <meta
          name="description"
          itemprop="description"
          content={blogDetailData?.blog_meta_desc != null ? blogDetailData?.blog_meta_desc :"Pink Pearl"}
        />
        <meta name="keywords" content={blogDetailData.blog_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Pink Pearl" />
        <meta property="og:title" content={blogDetailData.blog_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Pink Pearl" />
        {pageData.page_header_image != null ? (
          <meta
            property="og:image"
            content={headerImageUrl + pageData.page_header_image}
          />
        ) : (
          <meta property="og:image" content={"/assets/img/logo.png"} />
        )}
        <meta property="og:url" content={window.location.href} />
        {blogDetailData?.blog_meta_desc != null ? (
            <meta property="og:description" content={blogDetailData?.blog_meta_desc } />
            ) : (
            <meta property="og:description" content="Pink Pearl" />
            )}
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Pink Pearl" />
        <meta name="twitter:title" content={blogDetailData.blog_meta_desc} />
        {blogDetailData?.blog_meta_desc != null ? (
                    <meta property="twitter:description" content={blogDetailData?.blog_meta_desc} />
                ) : (
                    <meta property="twitter:description" content="Pink Pearl" />
                )}
             {pageData?.page_header_image ? (
                  <meta property="twitter:image" content={ headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                  property="twitter:image"
                    content={constant.FRONT_URL + 'assets/images/logo.png'}
                  />
                )}
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>

      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{
            backgroundImage: `url(${headerImageUrl + pageData.page_header_image
              })`,
            backgroundSize: "cover",
            backgroundPosition: "100%",
          }}
        ></div>
        <div className="page-header-bubble">
          <img
            src="/assets/images/shapes/page-header-bubble.png"
            alt="blog_image"
          />
        </div>
        <div className="container">
          <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>{pageData.page_name}</li>
            </ul>
            {/* <h2>{blogDetailData.blog_name}</h2> */}
          </div>
        </div>
      </section>

      <section className="blog-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              {blogDetailData != null ? (
                <div className="blog-details__left">
                  <div className="blog-details__img">
                    <img
                      src={
                        blogDetailData.blog_image != null
                          ? blogDetailImageUrl + blogDetailData.blog_image
                          : constant.DEFAULT_IMAGE
                      }
                      className="mb-30"
                      alt={blogDetailData.blog_image}
                    />
                  </div>
                  <div className="blog-details__content">
                    <ul className="list-unstyled blog-details__meta">
                      <li>
                        <a href="">
                          <i className="far fa-user-circle"></i> by Admin
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="far fa-calendar"></i>
                          {moment(blogDetailData.created_at).format(`D MMMM`)}
                        </a>
                      </li>
                    </ul>
                    <h1 className="blog-details__title">
                      {blogDetailData.blog_name}
                    </h1>

                    <p
                      className="blog-details__text-1"
                      dangerouslySetInnerHTML={{
                        __html: blogDetailData.blog_desc,
                      }}
                    ></p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-xl-4 col-lg-5">
              <SidebarDetails />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
export default BlogsDetails;
