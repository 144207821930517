import axios from "axios";
import constant from "./constant";

const settingsdataUrl = "setting-data"
const pageUrl = "page-data";
const galleryUrl = "gallery";
const sliderDataUrl = "slider-data";
const getfeaturedblogUrl = "featured-blogs";  
const blogsDataUrl = "blog-list"
const blogdetailsUrl = "blog-details" ;
const blogCategoryDataUrl = "blogs-category"
const blogTagsDataUrl = "blogs-tags"
const categorywiseblogsDataUrl = "categorywiseblogs"
const tagwiseblogsDataUrl = "tagwiseblogs"
const contactusprocessUrl ="contact-us-process";
const newsletterurl = "newsletter";
const activitiesUrl = "activities";
const packageslistUrl = "packages-list";
const footersUrl = "footer-data"
const ticketslistUrl = "tickets-list";
const ridesgalleryUrl = "rides";
const gettransactiondetailsUrl = "gettransactiondetails";
const headerDataUrl = "header-data";
const maingalleryDataUrl = "main-gallery";








const client = axios.create({
    baseURL: constant.API_URL,
  });
  export class ApiService {

  settingsdataRequest(){
  return client.get(settingsdataUrl)
}

pagePostRequest(request) {
  return client.post(pageUrl, request)
}

applyCouponPostRequest(url,request) {
  return client.post(url, request)
}

postRequest(url,request) {
  return client.post(url, request)
}

galleryGetRequest(){
  return client.get(galleryUrl);
 }
 maingalleryDataGetRequest(){
  return client.get(maingalleryDataUrl);
 }

 sliderDataGetRequest(){
  return client.get(sliderDataUrl);
 }
 getfeaturedblogs(request){
  return client.post(getfeaturedblogUrl,request);
}

blogDataGetRequest() {
  return client.get(blogsDataUrl);
}

blogdetailsPostRequest(request) {
  return client.post(blogdetailsUrl, request)
}

blogCategoryDataGetRequest() {
  return client.get(blogCategoryDataUrl);
}

blogTagsDataGetRequest() {
  return client.get(blogTagsDataUrl);
}
categorywiseblogsDataPostRequest(request){
  return client.post(categorywiseblogsDataUrl,request);
}
tagwiseblogsDataPostRequest(request){
  return client.post(tagwiseblogsDataUrl,request);
}
contactusprocessPostRequest(request) {
  return client.post(contactusprocessUrl, request)
}
newsletterPostRequest(request) {
  return client.post(newsletterurl, request)
}

gettransactiondetails(request) {
  return client.post(gettransactiondetailsUrl, request)
}

activitiesDataGetRequest() {
  return client.get(activitiesUrl);
}

packageslistGetRequest(){
  return client.get(packageslistUrl);
 }

 footersDataGetRequest() {
  return client.get(footersUrl);
}
// ticketslistGetRequest(request){
//   return client.post(ticketslistUrl,request);
//  }

 ridesgalleryDataGetRequest() {
  return client.get(ridesgalleryUrl);
}

headerDataGetRequest() {
  return client.get(headerDataUrl);
}
ticketslistPostRequest(request){
      return client.post(ticketslistUrl,request)
    }
  }
