import React, { useEffect, useState, useRef,useContext } from 'react';
import { ApiService } from '../Services/apiservices';
import { useLocation } from "react-router-dom";
import Offers from '../Modal';
import DataContext from '../Elements/eventContext';
let apiServices = new ApiService();
function Header() {
    const contextValues = useContext(DataContext);
    const location = useLocation();
    const didMountRef = useRef(true)
    const [show , setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(()=>{
        if(didMountRef.current){       
            apiServices.settingsdataRequest().then(res =>{
                if(res.data.status == "success"){
                    contextValues.setSettingData(res.data.sitesettings)
                    contextValues.setSettingImageBaseUrl(res.data.settings_url)

                    
                }
            })
        }
        didMountRef.current = false;
    })

    const openNavBar = () =>{
        const mobileNavWrapper = document.getElementsByClassName("mobile-nav__wrapper")[0];
        const customCursor = document.getElementsByClassName("custom-cursor")[0];
        mobileNavWrapper.classList.add("expanded");
        customCursor.classList.add("locked");
    }
return (
    <>
         {contextValues.settingData.marquee_tag ? (
          <div className="top-header">
            <div className ="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-12">
                  <marquee>{contextValues.settingData.marquee_tag}</marquee>
                </div>
              </div>
              </div>
          </div>
                ) : null}
       
        <header className="main-header-two clearfix">
            <nav className="main-menu main-menu-two clearfix">
                <div className="main-menu-two__wrapper clearfix">
                    <div className="container">
                        <div className="main-menu-two__wrapper-inner clearfix">
                            <div className="main-menu-two__left">
                                <div className="main-menu-two__logo">
                                
                                    <a href="/"><img src={contextValues.settingData.logo ? contextValues.settingImageBaseUrl + contextValues.settingData.logo :"assets/images/loogo.png"} alt="logo"/></a>
                                </div>
                                <div className="main-menu-two__main-menu-box">
                                    <a href="#" className="mobile-nav__toggler" onClick={openNavBar}><i className="fa fa-bars"></i></a>
                                    <ul className="main-menu__list">
                                        <li className={location.pathname ==="/" ? "current":""}><a href="/" >Home</a></li>
                                        <li className={location.pathname.includes("/about") ? "current":""}><a href="/about" >About</a></li>
                                        <li className={location.pathname.includes("/rides") ? "current":""}><a href="/rides" >Rides</a></li>
                                        <li className={location.pathname.includes("/packages") ? "current":""}><a href="/packages" >Packages</a></li>
                                        <li className={location.pathname.includes("/hotel")  ? "current":""}><a href="/hotel" >Hotel</a></li>
                                        <li className={location.pathname.includes("/blogs") ? "current":""}><a href="/blogs">Blogs</a></li>
                                        <li className={location.pathname.includes("/gallery") ? "current":""}><a href="/gallery">Gallery</a></li>

                                        <li className={location.pathname.includes("/contact")  ? "current":""}><a href="/contact" >Contact</a></li>
                                        <li className={location.pathname.includes("/book-tickets")  ? "current":""}><a href="/book-tickets" >BOOK TICKETS</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="main-menu-two__right">
                                <div className="main-menu-two__search-call">
                                    <div className="main-menu-two__call">
                                        <div className="main-menu-two__call-icon">
                                            <span className="icon-phone-call"></span>
                                        </div>
                                        <div className="main-menu-two__call-content">
                                            <p className="main-menu-two__call-sub-title">Contact Us</p>
                                            <h4 className="main-menu-two__call-number">
                                                <a href={"tel:" + contextValues.settingData.admin_support_mobile} className='blink mb-5'
                                            >{contextValues.settingData.admin_support_mobile}</a><br/>
                                                 <a href={"tel:" + contextValues.settingData.admin_number} className='blink'
                                            >{contextValues.settingData.admin_number}</a>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
        <div className="stricky-header stricked-menu main-menu main-menu-two">
                <div className="sticky-header__content"></div>
            </div>
        <div className='sidebutton'>
            <a href="/book-tickets">Book Tickets</a>
            <a href="https://chokhidhani.in/" target='_blank'>Hotel Bookings</a>
        </div>
       
       
        {/* <Offers
            
            show={show}
            setShow={setShow}
            handleClose={handleClose}
            handleShow={handleShow}
            
            /> */}
    </>
    
)}
export default Header