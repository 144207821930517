import React, { useEffect, useState, useRef,useContext } from 'react';
import { ApiService } from '../Services/apiservices';
import { useLocation } from "react-router-dom";
import CorporateBookingModal from "../../Containers/CorporateBookingModal";
import DataContext from '../Elements/eventContext';
let apiServices = new ApiService();
function Footer(){
    const[settingData, setSettingData] = useState("")
    const contextValues = useContext(DataContext);
    const location = useLocation();
    const[imageUrl, setImageUrl] = useState("")
    const [settingImageBaseUrl, setSettingImageBaseUrl] = useState('');
    const [Email , setEmail] = useState('')
    const [successMessage, setSuccessmessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [footer1, setFooter1] = useState('')
    const [footer2, setFooter2] = useState('')
    const [footer3, setFooter3] = useState('')
    const [footer4, setFooter4] = useState('')
    const [whatsappUrl , setWhatsappUrl] = useState('')

    const didMountRef = useRef(true)

    useEffect(()=>{
        if(didMountRef.current){


            apiServices.footersDataGetRequest().then(res => {
                if (res.data.status === "success") {
                    setFooter1(res.data.footer.footer_desc1)
                    setFooter2(res.data.footer.footer_desc2)
                    setFooter3(res.data.footer.footer_desc3)
                    setFooter4(res.data.footer.footer_desc4)

                }
 
            })
            apiServices.settingsdataRequest().then((res) => {
                if (res.data.status == "success") {
                    setWhatsappUrl(res.data.sitesettings.admin_whatsapp_mobile ? "https://wa.me/" + res.data.sitesettings.admin_whatsapp_mobile : "")
                }
              });

        }
        didMountRef.current = false;

    })

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }
    
      const submitmessage = (e) => {
        e.preventDefault()
        //console.log("Email",Email);
        if (Email === "") {
            document.getElementById("newsletter_email").style.border = "1px solid red"
            setEmail("")
            return false
            
        }
        if (!isValidEmail(Email)) {
          document.getElementById("newsletter_email").style.border = "1px solid red"
          setErrorMessage('Email is invalid');
          return false
        }    
        setErrorMessage(''); 
            document.getElementById("newsletter_email").style.border = "1px solid #aa8453"
            const dataString = {
                "newsletter_email": Email,
            }
    
        apiServices.newsletterPostRequest(dataString).then(res => { 
                    if (res.data.status === "success") {
                        setSuccessmessage(res.data.message)
                        setEmail("")
                    }
                    else{
                        setErrorMessage(res.data.message)
                        setEmail("")
                    }
    
                    setTimeout(() => {
                      document.getElementById("btn_timeout").click()
                      window.location.reload();
                    }, 2000);
                });
        }

    const closeNavBar = () =>{
        const mobileNavWrapper = document.getElementsByClassName("mobile-nav__wrapper")[0];
        const customCursor = document.getElementsByClassName("custom-cursor")[0];
        mobileNavWrapper.classList.remove("expanded");
        customCursor.classList.remove("locked");
    }
return(
    <>
        <footer className="site-footer">
            <svg className="editorial" viewBox="0 24 150 28" preserveAspectRatio="none">
                <defs>
                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                </defs>
                <g className="parallax">
                <use xlinkHref="#gentle-wave" x="50" y="0" fill="#caf1d4" />
                <use xlinkHref="#gentle-wave" x="50" y="3" fill="#a4d1ed" />
                <use xlinkHref="#gentle-wave" x="50" y="6" fill="#6acdb1" />
                <use xlinkHref="#gentle-wave" x="50" y="9" fill="#4bbed1" />
                </g>
            </svg>
            <div className="site-footer__top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                            <div className="footer-widget__column footer-widget__about">
                            <a href="/"><img src="/assets/images/lo.png" alt="logo" className='footer-logo'/></a>
                              
                            <div dangerouslySetInnerHTML={{ __html: footer1 }}></div>

                                <div className="site-footer__social">
                                    {
                                        contextValues.settingData.twitter_url != null ? 
                                            <a href={contextValues.settingData.twitter_url} target="new"><i className="fab fa-twitter"></i></a>
                                        :null
                                    }
                                    {
                                        contextValues.settingData.facebook_url != null ?
                                            <a href={contextValues.settingData.facebook_url} target="new"><i className="fab fa-facebook"></i></a>
                                        :null
                                    }
                                    {
                                        contextValues.settingData.pinterest_url != null ?
                                            <a href={contextValues.settingData.pinterest_url} target="new"><i className="fab fa-pinterest-p"></i></a>
                                        :null
                                    }
                                    {
                                        contextValues.settingData.instagram_url != null ?
                                            <a href={contextValues.settingData.instagram_url} target="new"><i className="fab fa-instagram"></i></a>
                                        :null
                                    }
                
                                </div>
                                {successMessage !==''?<div className="alert alert-success" role="alert" style={{color:"#6B3D42"}}>{successMessage}</div>:null}
                   {errorMessage !==''?<div className="alert alert-danger" role="alert" style={{color:"#6B3D42"}}>{errorMessage}</div>:null}
                                <form className="footer-widget__newsletter-form">
                                    <div className="footer-widget__newsletter-input-box">
                                        <input type="email" placeholder="subscribe newsletter" name="email" id="newsletter_email" onChange={(e) => setEmail(e.target.value)} value={Email} />
                                        <button type="submit" id="btn_timeout" className="footer-widget__newsletter-btn" onClick={submitmessage}><i className="far fa-paper-plane"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-2 col-5 wow fadeInUp"  >
                            <div className="footer-widget__column footer-widget__articles clearfix" dangerouslySetInnerHTML={{ __html: footer2 }}>
                            </div>
                            <div className="footer-widget__column footer-widget__articles clearfix">
                                <ul className="footer-widget__links-list list-unstyled clearfix">
                                    <li><CorporateBookingModal/></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-7 wow fadeInUp" dangerouslySetInnerHTML={{ __html: footer3 }} ></div>
                        <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                            <div className="footer-widget__column footer-widget__newsletter">
                                <div className="footer-widget__column footer-widget__links clearfix" dangerouslySetInnerHTML={{ __html: footer4 }} ></div>

                            </div>
                           
                        </div>
                    </div>
                    <div className="information__inner">
                        <ul className="list-unstyled information__list">
                            <li>
                                <div className="information__icon">
                                    <span className="icon-phone"></span>
                                </div>
                                <div className="information__content">
                                    <p className="information__sub-title">Call anytime</p>
                                    <h5 className="information__number">
                                        <a href={"tel:"+ contextValues.settingData.admin_support_mobile} className='mb-5'>{contextValues.settingData.admin_support_mobile}</a><br/>
                                        <a href={"tel:"+ contextValues.settingData.admin_number}>{contextValues.settingData.admin_number}</a><br/>
                                        
                                    </h5>
                                </div>
                            </li>
                            <li>
                                <div className="information__icon">
                                    <span className="icon-envelope"></span>
                                </div>
                                <div className="information__content">
                                    <p className="information__sub-title">Send email</p>
                                    <h5 className="information__number">
                                        <a href={"mailto:" + contextValues.settingData.admin_email}>{contextValues.settingData.admin_email}</a>
                                    </h5>
                                </div>
                            </li>
                            <li>
                                <div className="information__icon">
                                    <span className="icon-location-1"></span>
                                </div>
                                <div className="information__content">
                                    <p className="information__sub-title">Visit office</p>
                                    <h5 className="information__number">
                                        <a href="https://goo.gl/maps/asJg8X2FW5hU5SQB8" target="new">{contextValues.settingData.address}</a>
                                    </h5>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="site-footer__bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="site-footer__bottom-inner">
                                <p className="site-footer__bottom-text">© Copyright 2024 by <a href="/">Pink Pearl</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div className="mobile-nav__wrapper">
            <div className="mobile-nav__overlay mobile-nav__toggler" onClick={closeNavBar}></div>
            <div className="mobile-nav__content">
                <span className="mobile-nav__close mobile-nav__toggler" onClick={closeNavBar}><i className="fa fa-times"></i></span>
                <div className="logo-box">
                    <a href="#" aria-label="logo image">
                        <img src="/assets/images/resources/logo-1.png" width="89" alt="" />
                    </a>
                </div>
                <div className="mobile-nav__container">
                    <ul className="main-menu__list">
                        <li className={location.pathname ==="/" ? "current":""}><a href="/" >Home</a></li>
                        <li className={location.pathname.includes("/about") ? "current":""}><a href="/about" >About</a></li>
                        <li className={location.pathname.includes("/rides") ? "current":""}><a href="/rides" >Rides</a></li>
                        <li className={location.pathname.includes("/packages") ? "current":""}><a href="/packages" >Packages</a></li>
                        <li className={location.pathname.includes("/hotel")  ? "current":""}><a href="/hotel" >Hotel</a></li>
                        <li className={location.pathname.includes("/blogs") ? "current":""}><a href="/blogs">Blogs</a></li>
                        <li className={location.pathname.includes("/contact")  ? "current":""}><a href="/contact" >Contact</a></li>
                        <li className={location.pathname.includes("/book-tickets")  ? "current":""}><a href="/book-tickets" >BOOK TICKETS</a></li>
                    </ul>
                </div>
                <ul className="mobile-nav__contact list-unstyled">
                    <li>
                        <i className="fa fa-envelope"></i>
                        <a href={"mailto:" + contextValues.settingData.admin_email}>{contextValues.settingData.admin_email}</a>
                    </li>
                    <li>
                        <i className="fa fa-phone-alt"></i>
                        <a href={"tel:"+ contextValues.settingData.admin_mobile}>{contextValues.settingData.admin_mobile}</a>
                    </li>
                </ul>
                <div className="mobile-nav__top">
                    <div className="mobile-nav__social">
                    {
                                        contextValues.settingData.twitter_url != null ? 
                                            <a href={contextValues.settingData.twitter_url} target="new"><i className="fab fa-twitter"></i></a>
                                        :null
                                    }
                                    {
                                        contextValues.settingData.facebook_url != null ?
                                            <a href={contextValues.settingData.facebook_url} target="new"><i className="fab fa-facebook"></i></a>
                                        :null
                                    }
                                    {
                                        contextValues.settingData.pinterest_url != null ?
                                            <a href={contextValues.settingData.pinterest_url} target="new"><i className="fab fa-pinterest-p"></i></a>
                                        :null
                                    }
                                    {
                                        contextValues.settingData.instagram_url != null ?
                                            <a href={contextValues.settingData.instagram_url} target="new"><i className="fab fa-instagram"></i></a>
                                        :null
                                    }
                    </div>
                </div>
            </div>
        </div>
        <div className="whatsapp"><a href={whatsappUrl} target="new"><img src="/assets/images/whatsapp1.png" ></img></a></div>

    </>
)
}
export default Footer;
