import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Helmet } from "react-helmet";
import constant from "../../Components/Services/constant";

let apiServices = new ApiService();
function Contact() {
  const [pageData, setPageData] = useState({});
  const [pageContent, setPageContent] = useState("");
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [settingData, setSettingData] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Subject, setSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessmessage] = useState("");

  const { slug } = useParams();
  const didMountRef = useRef(true);

  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
        page_url: "contact",
      };
      apiServices.pagePostRequest(dataString).then((res) => {
        if (res.data.status === "success") {
          setPageData(res.data.pageData);
          setPageContent(res.data.pageData.page_content);
          setHeaderImageUrl(res.data.page_header_url);
        }
      });
    }

    apiServices.settingsdataRequest().then((res) => {
      if (res.data.status == "success") {
        setSettingData(res.data.sitesettings);
        setImageUrl(res.data.settings_url);
      }
    });

    didMountRef.current = false;
  }, []);

  const submitmessage = (e) => {
    e.preventDefault();
    if (Name == "") {
      document.getElementById("contact-name").style.border = "1px solid red";
      return false;
    } else if (Email == "") {
      document.getElementById("contact-email").style.border = "1px solid red";
      return false;
    } else if (Mobile == "") {
      document.getElementById("contact-phone").style.border = "1px solid red";
      return false;
    } else if (Subject == "") {
      document.getElementById("contact-subject").style.border = "1px solid red";
      return false;
    } else if (Message == "") {
      document.getElementById("contact-message").style.border = "1px solid red";
      return false;
    } else {
      setName("");
      setEmail("");
      setMobile("");
      setSubject("");
      setMessage("");
      document.getElementById("contact-name").style.border = "1px solid var()";
      document.getElementById("contact-email").style.border = "1px solid var()";
      document.getElementById("contact-phone").style.border = "1px solid var()";
      document.getElementById("contact-subject").style.border = "1px solid var()";
      document.getElementById("contact-message").style.border = "1px solid var()";

      const dataString = {
        contact_enquiry: pageData.page_id,

        contact_name: Name,
        contact_email: Email,
        contact_mobile: Mobile,
        contact_subject: Subject,
        contact_message: Message,
      };
      apiServices.contactusprocessPostRequest(dataString).then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          setSuccessmessage(res.data.message);
        } else if (res.data.status === "error") {
          setErrorMessage(res.data.message);
        }
        setTimeout(() => {
          setSuccessmessage("");
        }, 3000);
      });
    }
  };

  return (
    <>
      <Helmet>
      <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Pink Pearl"}</title>
      <meta
          name="description"
          itemprop="description"
          content={pageData?.page_meta_desc != null ? pageData?.page_meta_desc :"Pink Pearl"}
        />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Pink Pearl" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Pink Pearl" />
        <meta property="og:url" content={window.location.href} />
        {pageData?.page_meta_desc != null ? (
            <meta property="og:description" content={pageData?.page_meta_desc } />
            ) : (
            <meta property="og:description" content="Pink Pearl" />
            )}
        {pageData?.page_header_image ? (
                  <meta property="og:image" content={headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                    property="og:image"
                    content={constant.FRONT_URL + 'assets/images/logo.png'}
                  />
                )}
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Pink Pearl" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        {pageData?.page_meta_desc != null ? (
                    <meta property="twitter:description" content={pageData?.page_meta_desc} />
                ) : (
                    <meta property="twitter:description" content="Pink Pearl" />
                )}
        {pageData?.page_header_image ? (
                  <meta property="twitter:image" content={ headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                  property="twitter:image"
                    content={constant.FRONT_URL + 'assets/images/logo.png'}
                  />
                )}
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>

      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{
            backgroundImage: `url(${
              headerImageUrl + pageData.page_header_image
            })`,
            backgroundSize: "cover",
            backgroundPosition: "100%",
          }}
        ></div>
        <div className="page-header-bubble">
          <img src="/assets/images/shapes/page-header-bubble.png" alt="image" />
        </div>
        <div className="container">
          <div className="page-header__inner">
            <h1>{pageData.page_header}</h1>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>{pageData.page_name}</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="contact-page-two">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <div className="contact-page-two__left">
                <div className="section-title text-left">
                  <span className="section-title__tagline">Contact Now</span>
                  <h2 className="section-title__title">
                    Feel Free to Write us
                  </h2>
                </div>
                <ul className="list-unstyled contact-page-two__info">
                  <li>
                    <div className="icon">
                      <span className="icon-message"></span>
                    </div>
                    <div className="text">
                      <p>
                        <a href={"mailto:" + settingData.admin_email}>
                          {settingData.admin_email}
                        </a>
                      </p>
                      <h5>
                        <a href={"mailto:" + settingData.admin_email}>
                          Send mail
                        </a>
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="icon-phone-call"></span>
                    </div>
                    <div className="text">
                      <p>Call Anytime</p>
                      <h5>
                        <a href={"tel:" + settingData.admin_support_mobile}>
                          {settingData.admin_support_mobile}
                        </a><br/>
                        <a href={"tel:" + settingData.admin_number}>
                          {settingData.admin_number}
                        </a>
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="icon-location-1"></span>
                    </div>
                    <div className="text">
                      <p>Visit office</p>
                      <h5>
                        <a
                          href="https://goo.gl/maps/asJg8X2FW5hU5SQB8"
                          target="new"
                        >
                          {settingData.address}
                        </a>
                      </h5>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7">
              <div className="contact-page-two__right">
                <div className="section-title text-left">
                  <span className="section-title__tagline">
                    Get a Free Estimate{" "}
                  </span>
                  <h2 className="section-title__title">Contact Us </h2>
                </div>
                {successMessage != "" ? (
                  <div className="alert alert-success" role="alert">
                    {successMessage}
                  </div>
                ) : (
                  ""
                )}
                {errorMessage != "" ? (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                ) : (
                  ""
                )}
                <form
                  action="/assets/inc/sendemail.php"
                  className="contact-one__form contact-form-validated"
                  noValidate="noValidate"
                >
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="contact-one__form-input-box">
                        <input
                          name="contact-name"
                          id="contact-name"
                          type="text"
                          placeholder="Full name"
                          onChange={(e) => setName(e.target.value)}
                          value={Name}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="contact-one__form-input-box">
                        <input
                          name="contact-email"
                          id="contact-email"
                          type="email"
                          placeholder="Your email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={Email}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="contact-one__form-input-box">
                        <input
                          name="contact-phone"
                          id="contact-phone"
                          type="text"
                          placeholder="Phone number"
                          onChange={(e) => setMobile(e.target.value)}
                          value={Mobile}
                        />
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="contact-one__form-input-box">
                        <input
                          name="contact-subject"
                          id="contact-subject"
                          type="text"
                          placeholder="Subject"
                          onChange={(e) => setSubject(e.target.value)}
                          value={Subject}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="contact-one__form-input-box text-message-box">
                        <textarea
                          name="contact-message"
                          id="contact-message"
                          placeholder="Write message"
                          onChange={(e) => setMessage(e.target.value)}
                          value={Message}
                        ></textarea>
                      </div>
                      <div className="contact-one__btn-box">
                        <button
                          type="submit"
                          className="thm-btn contact-one__btn"
                          name="submit"
                          onClick={submitmessage}
                        >
                          Send a message <i className="fa fa-angle-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="google-map google-map--two">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.345685706812!2d75.66945079999999!3d26.8607561!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4b5a41a2c045%3A0xadce44dc7b2b133b!2sPink%20Pearl%20Hotel!5e0!3m2!1sen!2sin!4v1687497178071!5m2!1sen!2sin"
          width={"100%"}
          height={"800"}
          style={{ border: "0" }}
          allowFullScreen=""
          loading={"lazy"}
        ></iframe>
      </section>

      <Footer />
    </>
  );
}

export default Contact;
